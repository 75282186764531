import styled from "styled-components";

const MainWrapper = styled.main`
  flex: 1 0;
  padding: 0 24px;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0 15px;
  }
`;

export default MainWrapper;
