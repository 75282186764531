import styled, { keyframes } from "styled-components";
import { rollIn } from "react-animations";

import AvatarTextWrapper from "../../../containers/avatar-text/avatar-text.styles";

import rem from "../../../utils/rem";
import AvatarWrapper from "../../avatar/avatar.styles";

const rollAnimation = keyframes`${rollIn}`;

const SideBarWrapper = styled.aside`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0%;
  width: ${({ theme }) => theme.layout.collapsedWidth};
  background-color: #fafafb;
  padding: 16px;
  transition-property: width, opacity;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  border-right: 1px solid #ebedee;

  .link-item-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .link-item {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.grey[700]};
    border-radius: 6px;
    font-size: ${rem(14)};
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-property: width, height, background-color, padding, font-weight;
    animation: 0.3s ${rollAnimation};
    cursor: pointer;

    > svg {
      font-size: ${rem(14)};
      flex-shrink: 0;
    }

    > span {
      display: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      line-height: 16px;
    }

    &.active,
    &:hover {
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(23, 30, 68, 0.08);
      font-weight: 600;
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    &__logo {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 8px;
      border-radius: 6px;

      ${AvatarTextWrapper} {
        ${AvatarWrapper} {
          margin-right: 0;
        }

        .content {
          display: none;
        }
      }

      &__caret {
        color: ${({ theme }) => theme.colors.text};
        margin-left: auto;
        display: none;
        transition: transform 0.2s ease;
      }

      &.hoverable {
        cursor: pointer;

        &:hover {
          outline: 4px solid #fcd2ba;
        }
      }
    }

    &__closer {
      color: ${({ theme }) => theme.colors.text};
      flex-shrink: 0;
      display: none;
      font-size: ${rem(20)};
      cursor: pointer;
      padding: 4px;
      border-radius: 2px;

      &:hover,
      &:active,
      &:focus {
        background-color: rgba(255, 255, 255, 0.3);
      }

      > svg {
        display: block;
      }
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      display: flex;
      align-items: center;

      &__closer {
        display: block;
      }
    }
  }

  .switcher-list-wrapper {
    padding: 8px;

    + .switcher-list-wrapper {
      border-top: 1px solid ${({ theme }) => theme.colors.grey[100]};
    }

    &.is-primary {
      max-height: 150px;
      overflow-y: auto;
    }
  }

  .body-wrapper {
    flex: 1;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .footer-wrapper {
    margin-top: auto;

    &__logo {
      display: flex;
      justify-content: center;
      margin-top: 24px;

      &__big {
        height: 20px;
        margin-left: 8px;
        display: none;
      }

      &__default {
        width: 14px;
      }
    }
  }

  &:hover,
  &.expanded,
  &.open {
    width: ${({ theme }) => theme.layout.expandedWidth};

    .title-wrapper {
      justify-content: start;

      &__logo {
        ${AvatarTextWrapper} {
          ${AvatarWrapper} {
            margin-right: 8px;
          }

          .content {
            display: inline-block;
          }
        }

        &__caret {
          display: inline;
        }
      }
    }

    .footer-wrapper {
      &__logo {
        justify-content: start;

        &__big {
          display: inline;
        }

        &__default {
          display: none;
        }
      }
    }

    .link-item {
      justify-content: start;
      width: 100%;
      height: auto;
      padding: 8px 10px;

      > svg {
        margin-right: 8px;
      }

      > span {
        display: inline;
      }
    }
  }

  &.open {
    .title-wrapper__logo {
      outline: 4px solid #fcd2ba;

      &__caret {
        color: ${({ theme }) => theme.colors.primary.base};
        transform: rotate(-180deg);
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    visibility: hidden;
    opacity: 0;
    border-right: 1px solid ${({ theme }) => theme.colors.grey[300]};

    &.expanded {
      visibility: visible;
      opacity: 1;
    }
  }
`;

export default SideBarWrapper;
