import { forwardRef, useCallback, useEffect, useRef } from "react";
import CheckboxWrapper from "./checkbox.styles";
import Tooltip from "../../tooltip/tooltip.component";

import { ICheckboxProps } from "./checkbox.interfaces";

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      id,
      name,
      className,
      checked,
      value,
      disabled,
      onChange,
      onBlur,
      label,
      labelTooltip,
      inputProps,
      indeterminate,
      removeIndeterminateStyles,
      ...props
    },
    ref
  ) => {
    const defaultRef = useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (resolvedRef && "current" in resolvedRef && resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate || false;
      }
    }, [resolvedRef, indeterminate]);

    const disableElementClick = useCallback(
      (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
        e.preventDefault();
      },
      []
    );

    return (
      <CheckboxWrapper $disabled={disabled} className={className} {...props}>
        <input
          id={id}
          type="checkbox"
          name={name}
          ref={resolvedRef}
          onChange={onChange}
          onBlur={onBlur}
          checked={checked}
          value={value}
          disabled={disabled}
          {...inputProps}
          className={
            inputProps?.className || removeIndeterminateStyles
              ? `${inputProps?.className || ""}${
                  removeIndeterminateStyles
                    ? `${inputProps?.className ? " " : ""}no-indetermiante-styles`
                    : ""
                }`
              : undefined
          }
        />

        <label
          htmlFor={id}
          onClick={inputProps?.readOnly ? disableElementClick : undefined}
        >
          <span className="checkbox-element" tabIndex={0} />
          {label ? (
            <span className="checkbox-text">
              {label}
              {labelTooltip ? <Tooltip showInformer>{labelTooltip}</Tooltip> : null}
            </span>
          ) : null}
        </label>
      </CheckboxWrapper>
    );
  }
);

export default Checkbox;
