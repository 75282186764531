import { transparentize } from "polished";
import { css } from "styled-components";
import rem from "../../utils/rem";

export const togglerBgStyles = (dark?: boolean, outline?: boolean) => css`
  &:hover {
    background-color: ${({ theme }) =>
      dark ? theme.colors.black[300] : theme.colors.white[400]};
  }

  &.open,
  &.active {
    background-color: ${({ theme }) =>
      dark ? theme.colors.black[400] : theme.colors.white[500]};
    outline: ${({ theme }) =>
      outline
        ? `4px solid ${transparentize(0.7, theme.colors.primary[300])}`
        : "none"};
  }

  &.disabled {
    background-color: ${({ theme }) =>
      dark ? theme.colors.black[400] : theme.colors.white[500]};
    outline: none;
  }
`;

export const togglerStyles = (
  size?: "large" | "medium" | "small",
  hide?: boolean,
  dark?: boolean,
  iconOnly?: boolean,
  outline?: boolean
) => css`
  display: ${hide ? "none" : "inline-flex"};
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: ${({ theme }) => theme.heights[size || "large"]};
  min-width: ${({ theme }) =>
    iconOnly ? theme.heights[size || "large"] : undefined};
  padding: ${size === "medium"
    ? iconOnly
      ? "7px"
      : "7px 11px"
    : size === "small"
    ? iconOnly
      ? "5px"
      : "5px 7px"
    : iconOnly
    ? "11px"
    : "11px 15px"};
  border-radius: 6px;
  align-items: center;
  font-size: ${rem(13)};
  font-weight: 500;
  color: ${({ theme }) => (dark ? theme.colors.grey[800] : theme.colors.grey[600])};
  cursor: pointer;
  ${togglerBgStyles(dark, outline)};

  > svg {
    display: block;
  }

  .icon-wrapper {
    margin-right: 7px;

    > svg {
      display: block;
    }
  }

  .caret {
    transition: transform 0.2s ease;
    margin-left: 10px;

    > svg {
      display: block;
    }
  }

  &.active,
  &.open {
    color: ${({ theme }) => (dark ? theme.colors.grey[900] : "#ffffff")};
  }

  &.open {
    .caret {
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }
`;

export const dropdownItemStyles = css`
  flex: 1;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.spaceRadius.xxms};
  color: ${({ theme }) => theme.colors.grey[700]};
  font-size: ${rem(14)};
  font-weight: 500;
  margin-bottom: 2px;
  position: relative;
  transition-property: color, background-color;
  cursor: pointer;

  > svg {
    font-size: ${rem(14)};
    margin-right: 8px;
    flex-shrink: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const dropdownItemHoverStyles = css`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  color: ${({ theme }) => theme.colors.darkBlue[500]};
`;

export const dropdownItemActiveStyles = css`
  color: ${({ theme }) => theme.colors.darkBlue[500]};

  &:not(.no-marker) {
    padding-right: 31px;

    &::before {
      content: "";
      height: 15px;
      width: 15px;
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.primary.base};
      position: absolute;
      top: 50%;
      right: 8px;
      border-radius: 50%;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      height: 7px;
      width: 3px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.primary.base};
      position: absolute;
      top: calc(50% - 1px);
      right: 14.5px;
      transform: translateY(-50%) rotate(45deg);
    }
  }
`;

export const dropdownItemDisabledStyles = css`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  color: ${({ theme }) => theme.colors.grey[600]};
  cursor: not-allowed;

  &::before,
  &::after {
    border-color: ${({ theme }) => theme.colors.grey[600]};
  }
`;

export const editDeleteIconsStyles = (hidden?: boolean) => css`
  margin-left: 10px;
  cursor: pointer;
  opacity: ${hidden ? "0" : undefined};
  visibility: ${hidden ? "hidden" : undefined};
  transition: color 0.2s ease;

  &--edit-toggler {
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.primary.base};
    }
  }

  &--deleter {
    &:hover,
    &:active,
    &:focus {
      color: ${({ theme }) => theme.colors.red.base};
    }
  }
`;
