export enum ModalTypesEnum {
  ADD_CLASS = "add-class",
  EDIT_CLASS = "edit-class",
  ADD_ASSIGNMENT = "add-assignment",
  ADD_MULTI_CLASS_ASSIGNMENT = "add-multi-class-assignment",
  EDIT_ASSIGNMENT = "edit-assignment",
  SINGLE_ASSIGNMENT = "assignment",
  SINGLE_STUDENT_ASSIGNMENT = "student-assignment",
  ADD_USER = "add-user",
  SINGLE_USER = "user",
  ADD_MESSAGE = "add-message",
  ADD_REPORT = "add-report",
  ADD_ANECDOTAL = "add-anecdotal",
  SINGLE_ANECDOTAL = "anecdotal",
}
