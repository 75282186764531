import { makeVar } from "shared/providers/apollo/apollo.package";
import { IFile } from "shared/common/interfaces/file.interfaces";

export interface IDistrictVar {
  id: string;
  name: string;
  isDeleted: boolean;
  timezone: string;
  url: string;
  logo: IFile | null;
  background: IFile | null;
  favicon: IFile | null;
}

/**
 * Store current district's data which we can access globally
 */

export const districtVar = makeVar<IDistrictVar | null>(null);
