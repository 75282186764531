import { makeVar } from "shared/providers/apollo/apollo.package";
import {
  IMainTitle,
  IMainTitleSelect,
  IMainTitleMultiSelect,
  IMainTitleAsyncSelect,
  IMainTitleAsyncMultiSelect,
} from "shared/common/interfaces/main-title.interfaces";

const DEFAULT_TITLE_VAR: IMainTitle = {
  type: "default",
  value: "Dashboard",
  metaTitle: "PRACTICE Software",
  metaDescription:
    "All in one platform for your virtual school, students and grades.",
};

/**
 * Control topbar title and meta
 */

export const titleVar = makeVar<
  | IMainTitle
  | IMainTitleSelect
  | IMainTitleMultiSelect
  | IMainTitleAsyncSelect
  | IMainTitleAsyncMultiSelect
>(DEFAULT_TITLE_VAR);

export const resetTitleVar = () => titleVar(DEFAULT_TITLE_VAR);
