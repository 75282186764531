import { makeVar } from "shared/providers/apollo/apollo.package";
import { IGlobalModal } from "./global-modals.var";

interface IDockVar extends IGlobalModal {
  name: string;
}

/**
 * Controls minimized dock tray
 */

export const dockVar = makeVar<IDockVar[]>([]);

export const resetDockVar = () => dockVar([]);
