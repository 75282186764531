import { useCallback, useMemo } from "react";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";
import { titleVar } from "../../common/apollo/vars/title.var";
import { globalModalsVar } from "../../common/apollo/vars/global-modals.var";
import { schoolAccessVar } from "../../common/apollo/vars/school-access.var";

import { useAuth } from "../../hooks/useAuth.hook";
import { useLogout } from "../../hooks/useLogout.hook";

import SharedTopbar from "shared/components/layout/topbar/topbar.component";

import { ModalTypesEnum } from "../../common/enums/modal-types.enum";

import {
  TbEdit,
  TbMessage,
  TbReportAnalytics,
  TbSchool,
  TbStar,
  TbUser,
} from "shared/providers/react-icons/tb.package";

import { RoleTypeEnum } from "../../generated/graphql";
import { TopbarMenuItem } from "shared/components/layout/topbar/topbar.interfaces";

const Topbar = () => {
  const title = useReactiveVar(titleVar);
  const schoolAccess = useReactiveVar(schoolAccessVar);

  const { user } = useAuth();
  const logout = useLogout();

  const handleAddClass = useCallback(() => {
    globalModalsVar([
      ...globalModalsVar(),
      {
        type: ModalTypesEnum.ADD_CLASS,
        noURLChange: true,
      },
    ]);
  }, []);

  const handleAddAssignment = useCallback(() => {
    globalModalsVar([
      ...globalModalsVar(),
      {
        type: ModalTypesEnum.ADD_ASSIGNMENT,
        noURLChange: true,
      },
    ]);
  }, []);

  const handleAddMessage = useCallback(() => {
    globalModalsVar([
      ...globalModalsVar(),
      {
        type: ModalTypesEnum.ADD_MESSAGE,
        noURLChange: true,
      },
    ]);
  }, []);

  const handleAddReport = useCallback(() => {
    globalModalsVar([
      ...globalModalsVar(),
      {
        type: ModalTypesEnum.ADD_REPORT,
        noURLChange: true,
      },
    ]);
  }, []);

  const handleAddAnecdotal = useCallback(() => {
    globalModalsVar([
      ...globalModalsVar(),
      {
        type: ModalTypesEnum.ADD_ANECDOTAL,
        noURLChange: true,
      },
    ]);
  }, []);

  const actionItems = useMemo(
    () =>
      user?.id
        ? [
            {
              text: "My Account",
              icon: TbUser,
              onClick: () =>
                globalModalsVar([
                  ...globalModalsVar(),
                  {
                    type: ModalTypesEnum.SINGLE_USER,
                    id: user?.id,
                  },
                ]),
            },
          ]
        : null,
    [user?.id]
  );

  const quickItems = useMemo(() => {
    const items: TopbarMenuItem[] = [];

    if (
      schoolAccess?.role.type === RoleTypeEnum.SchoolAdmin ||
      schoolAccess?.role.type === RoleTypeEnum.Teacher
    ) {
      items.push({
        text: "Send Message",
        onClick: handleAddMessage,
        icon: TbMessage,
      });
    }

    if (schoolAccess?.role.type === RoleTypeEnum.SchoolAdmin) {
      items.push(
        ...[
          {
            text: "Add Report",
            onClick: handleAddReport,
            icon: TbReportAnalytics,
          },
          {
            text: "Add Class",
            onClick: handleAddClass,
            icon: TbSchool,
          },
        ]
      );
    }

    if (
      schoolAccess?.role.type === RoleTypeEnum.SchoolAdmin ||
      schoolAccess?.role.type === RoleTypeEnum.Teacher
    ) {
      items.push(
        ...[
          {
            text: "Add Assignment",
            onClick: handleAddAssignment,
            icon: TbStar,
          },
          {
            text: "Add Anecdotal",
            onClick: handleAddAnecdotal,
            icon: TbEdit,
          },
        ]
      );
    }

    return items;
  }, [
    schoolAccess?.role.type,
    handleAddMessage,
    handleAddClass,
    handleAddReport,
    handleAddAssignment,
    handleAddAnecdotal,
  ]);

  return (
    <SharedTopbar
      title={title}
      userFirstName={user?.firstName || ""}
      userLastName={user?.lastName || ""}
      userPhoto={user?.image?.url}
      userTitle={schoolAccess?.role.name}
      setExpanded={sidebarExpandedVar}
      logout={logout}
      actionItems={actionItems}
      quickItems={quickItems}
    />
  );
};

export default Topbar;
