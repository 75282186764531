import Description from "../description/description.styles";
import Title from "../title/title.styles";
import Separator from "../separator/separator.styles";
import ExternalLink from "../external-link/external-link.styles";

import UserInactiveWrapper from "./user-inactive.styles";
import { IUserInactiveProps } from "./user-inactive.interfaces";
import Button from "../button/button.component";

const UserInactive = ({ onLogout }: IUserInactiveProps) => {
  return (
    <UserInactiveWrapper>
      <div className="inactive-wrapper">
        <div className="inactive-wrapper__content">
          <div className="inactive-wrapper__content__block">
            <Title
              level={3}
              $spacing={{
                mb: "10px",
              }}
            >
              Your account is inactive!
            </Title>

            <Description>
              Please contact administrators for more information about your account.
            </Description>
          </div>

          <Separator />

          <div className="inactive-wrapper__content__block display-flex display-flex--a-center display-flex--j-between">
            <ExternalLink href="mailto:support@practicebc.com" target="_blank">
              Contact Support
            </ExternalLink>

            {onLogout ? (
              <Button variant="secondary" onClick={onLogout}>
                Logout
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </UserInactiveWrapper>
  );
};

export default UserInactive;
