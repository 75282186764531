import { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import { IDropdownMenuProps } from "./dropdown-menu.interfaces";
import DropdownMenuWrapper from "./dropdown-menu.styles";

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  referenceRef,
  open,
  $width,
  as,
  children,
  modifiers,
  ...props
}) => {
  const [popperElement, setPopperElement] = useState<HTMLUListElement | null>(null);

  const { styles, attributes, update } = usePopper(referenceRef, popperElement, {
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 5],
        },
      },
      {
        name: "preventOverflow",
      },
    ],
    ...modifiers,
  });

  useEffect(
    () => {
      if (open && update) {
        update();
      }
    },
    // eslint-disable-next-line
    [open]
  );

  return (
    <DropdownMenuWrapper
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      $width={$width}
      open={open}
      as={as}
      {...props}
    >
      {children}
    </DropdownMenuWrapper>
  );
};

export default DropdownMenu;
