import styled, { keyframes } from "styled-components";
import { transparentize } from "polished";
import { fadeIn } from "react-animations";
import { flex } from "../../styles/Theme/styled-options";

import { IModalStyles } from "./modal.interfaces";

const fadeInAnimation = keyframes`${fadeIn}`;

const ModalWrapper = styled.div<IModalStyles>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => transparentize(0.3, theme.colors.grey[600])};
  z-index: ${({ theme }) => theme.zIndex.modal};
  animation: 0.3s ${fadeInAnimation};

  > .modal__content-wrapper {
    background-color: #ffffff;
    width: ${({ $width }) =>
      $width === "full"
        ? "calc(100% - 32px)"
        : $width === "large"
        ? "775px"
        : $width === "medium"
        ? "545px"
        : $width === "small"
        ? "315px"
        : $width || "545px"};
    max-width: calc(100% - 32px);
    height: ${({ $height }) => ($height === "full" ? "calc(100% - 100px)" : "auto")};
    max-height: calc(100% - 100px);
    overflow: auto;
    border-radius: ${({ $borderRadius, theme }) =>
      $borderRadius || theme.spaceRadius.xxms};
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05), 0px 6px 25px rgba(0, 0, 0, 0.06);
    ${flex};
  }
`;

export default ModalWrapper;
