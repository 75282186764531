import { makeVar } from "shared/providers/apollo/apollo.package";
import { ModalTypesEnum } from "../../enums/modal-types.enum";

export interface IGlobalModal {
  type: ModalTypesEnum;
  id?: string | null;
  additional?: any;
  noURLChange?: true;
}

/**
 * Open global modal
 */

export const globalModalsVar = makeVar<IGlobalModal[]>([]);

export const resetGlobalModalsVar = () => globalModalsVar([]);
