import styled, { css } from "styled-components";
import { ICheckboxStyles } from "./checkbox.interfaces";
import { flex, spacing } from "../../../styles/Theme/styled-options";

const CheckboxWrapper = styled.div<ICheckboxStyles>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spaceRadius.form};
  display: flex;
  ${spacing};
  ${flex};

  .checkbox-element {
    position: relative;
    height: 16px;
    width: 16px;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.grey[200] : theme.colors.grey[500]};
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.colors.grey[200] : "#ffffff"};
    border-radius: ${({ theme }) => theme.spaceRadius.xs};
    transition-property: background-color, border-color;
    transition-duration: 0.2s;
    transition-timing-function: ease;
  }

  .checkbox-text {
    margin-left: 8px;
    color: ${({ theme, $disabled, labelColor }) =>
      labelColor
        ? labelColor
        : $disabled
        ? theme.colors.grey[600]
        : theme.colors.darkBlue[500]};
  }

  input {
    display: none;

    &:checked + label,
    &:indeterminate:not(.no-indetermiante-styles) + label {
      .checkbox-element {
        background-color: ${({ theme, $disabled }) =>
          $disabled ? undefined : theme.colors.primary.base};
        border-color: ${({ theme, $disabled }) =>
          $disabled ? undefined : theme.colors.primary.base};
      }
    }

    &:checked + label {
      .checkbox-element {
        &::after {
          content: "";
          height: 7px;
          width: 3px;
          border-bottom: 2px solid
            ${({ theme, $disabled }) =>
              $disabled ? theme.colors.grey[600] : "#ffffff"};
          border-right: 2px solid
            ${({ theme, $disabled }) =>
              $disabled ? theme.colors.grey[600] : "#ffffff"};
          position: absolute;
          top: calc(50% - 1px);
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
        }
      }

      ${({ $disabled, theme }) =>
        $disabled
          ? undefined
          : css`
              &:hover {
                .checkbox-element {
                  background-color: ${theme.colors.primary[400]};
                  border-color: ${theme.colors.primary[400]};
                  outline: none;
                }
              }

              .checkbox-element {
                &:focus {
                  background-color: ${theme.colors.primary.base};
                  border-color: ${theme.colors.primary.base};
                  outline: 4px solid ${theme.colors.primary[100]};
                }
              }
            `};
    }

    &:indeterminate:not(.no-indetermiante-styles) + label {
      .checkbox-element {
        &::after {
          content: "";
          height: 2px;
          width: 7px;
          background-color: ${({ theme, $disabled }) =>
            $disabled ? theme.colors.grey[600] : "#ffffff"};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 4px;
        }
      }

      ${({ $disabled, theme }) =>
        $disabled
          ? undefined
          : css`
              &:hover {
                .checkbox-element {
                  background-color: ${theme.colors.primary[400]};
                  border-color: ${theme.colors.primary[400]};
                  outline: none;
                }
              }

              .checkbox-element {
                &:focus {
                  background-color: ${theme.colors.primary.base};
                  border-color: ${theme.colors.primary.base};
                  outline: 4px solid ${theme.colors.primary[100]};
                }
              }
            `};
    }
  }

  label {
    display: inline-flex;
    align-items: center;
    cursor: ${({ customCursor, $disabled }) =>
      $disabled ? "not-allowed" : customCursor ? customCursor : "pointer"};

    ${({ $disabled, theme }) =>
      $disabled
        ? undefined
        : css`
            &:hover {
              .checkbox-element {
                border-color: ${theme.colors.primary.base};
              }
            }

            .checkbox-element {
              &:focus {
                border-color: #ffffff;
                outline: 4px solid ${theme.colors.primary[100]};
              }
            }
          `};
  }
`;

export default CheckboxWrapper;
