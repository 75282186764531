import styled from "styled-components";

const UserInactiveWrapper = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.layout.background};

  .inactive-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
      width: 450px;
      max-width: 100%;
      box-shadow: ${({ theme }) => theme.boxShadow.base};
      border-radius: 8px;
      background-color: #ffffff;

      &__block {
        padding: 16px;
      }
    }
  }
`;

export default UserInactiveWrapper;
