import React from "react";
import ReactDOM from "react-dom/client";

import StylesProvider from "shared/providers/styles.provider";
import HelmetProvider from "shared/providers/helmet.provider";
import DNDProvider from "shared/providers/dnd/dnd.provider";
import RouterProvider from "shared/providers/router/router.provider";
import DayjsProvider from "shared/providers/dayjs/dajys.provider";

import App from "./components/app/app.component";

import { ApolloProvider } from "shared/providers/apollo/apollo.package";
import apolloClient from "./common/apollo";

import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <StylesProvider>
      <HelmetProvider>
        <ApolloProvider client={apolloClient}>
          <DNDProvider>
            <RouterProvider>
              <DayjsProvider>
                <App />
              </DayjsProvider>
            </RouterProvider>
          </DNDProvider>
        </ApolloProvider>
      </HelmetProvider>
    </StylesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
