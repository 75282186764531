import Description from "../../description/description.styles";
import styled from "styled-components";
import rem from "../../../utils/rem";

import { down, spacing } from "../../../styles/Theme/styled-options";
import { ILabelProps } from "./label.interfaces";

const LabelWrapper = styled.div<ILabelProps>`
  max-width: 100%;
  ${spacing};
  ${down};

  .label-container {
    display: flex;
    align-items: center;
  }

  label {
    font-size: ${rem(13)};
    text-transform: none;
    padding: 0;
    color: ${({ theme, secondaryColor, $color }) =>
      $color
        ? $color
        : secondaryColor
        ? theme.colors.grey[600]
        : theme.colors.darkBlue[500]};
    font-weight: 600;

    > .required {
      color: ${({ theme }) => theme.colors.red.base};
      margin-left: 5px;
    }
  }

  ${Description} {
    display: block;
    margin-top: 5px;
    font-size: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export default LabelWrapper;
