import { DefaultTheme } from "styled-components";
import { BREAKPOINTS } from "../../common/data/constants";

import { IBreakpoints } from "../../common/interfaces/theme-options.interfaces";
import rem from "../../utils/rem";

/**
 * Keeping styled-components ts module here instead of common files because of the readability
 */

declare module "styled-components" {
  export interface ColorPallete {
    base: string;
    "50": string;
    "100": string;
    "200": string;
    "300": string;
    "400": string;
    "500": string;
    "600": string;
    "700": string;
    "800": string;
    "900": string;
  }

  export interface DefaultTheme {
    boxShadow: {
      base: string;
      button: string;
      card: string;
      popup: string;
      dropdown: string;
      modal: string;
    };
    spaceRadius: {
      xxs: string;
      xs: string;
      s: string;
      xxms: string;
      xms: string;
      ms: string;
      xxm: string;
      xm: string;
      m: string;
      xxml: string;
      xml: string;
      ml: string;
      xxl: string;
      xl: string;
      l: string;
      form: string;
    };
    heights: {
      small: string;
      medium: string;
      large: string;
    };
    colors: {
      primary: ColorPallete;
      grey: ColorPallete;
      red: ColorPallete;
      green: ColorPallete;
      blue: ColorPallete;
      darkBlue: ColorPallete;
      yellow: ColorPallete;
      orange: ColorPallete;
      white: ColorPallete;
      black: ColorPallete;
      background: string;
      text: string;
      headlines: string;
    };
    typography: {
      font: string;
      light: string;
      regular: string;
      medium: string;
      bold: string;
      h1: string;
      h2: string;
      h3: string;
      h4: string;
      h5: string;
      h6: string;
    };
    breakpoints: {
      up: (breakpoint?: keyof IBreakpoints) => string;
      down: (breakpoint?: keyof IBreakpoints) => string;
      between: (
        breakpoint1?: keyof IBreakpoints,
        breakpoint2?: keyof IBreakpoints
      ) => string;
      only: (breakpoint?: keyof IBreakpoints) => string;
    };
    zIndex: {
      stickyTop: string;
      stickyFooter: string;
      modal: string;
      dock: string;
      sidebar: string;
      popouts: string;
      textEditorBar: string;
      lightbox: string;
      layout: string;
    };
    layout: {
      background: string;
      collapsedWidth: string;
      expandedWidth: string;
      contentBottomPadding: string;
      topbarHeight: string;
      topbarSpacer: string;
    };
  }
}

function up(breakpoint?: keyof IBreakpoints) {
  const size = BREAKPOINTS[breakpoint || "lg"];
  return `@media screen and (min-width: ${size})`;
}

function down(breakpoint?: keyof IBreakpoints) {
  const size = BREAKPOINTS[breakpoint || "lg"];
  return `@media screen and (max-width: calc(${size} - 1px))`;
}

function between(
  breakpoint1?: keyof IBreakpoints,
  breakpoint2?: keyof IBreakpoints
) {
  const size1 = BREAKPOINTS[breakpoint1 || "lg"];
  const size2 = BREAKPOINTS[breakpoint2 || "lg"];
  if (breakpoint2) {
    return `@media screen and (min-width: ${size1}) and (max-width: ${size2})`;
  }
  return `@media screen and (max-width: ${size1})`;
}

function only(breakpoint?: keyof IBreakpoints) {
  switch (breakpoint) {
    case "sm":
      return between("sm", "md");
    case "md":
      return between("md", "lg");
    case "lg":
      return between("lg", "xl");
    case "xl":
      return between("xl", "xxl");
    case "xxl":
      return between("xxl");
    default:
      return between("md", "lg");
  }
}

const theme: DefaultTheme = {
  boxShadow: {
    base: "0px 1px 3px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05)",
    button: "0px 1px 0px rgba(0, 0, 0, 0.05)",
    card: "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
    popup: "0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1)",
    dropdown: "0px 2px 10px rgba(41, 45, 50, 0.1)",
    modal: "0px 0px 1px rgba(0, 0, 0, 0.2), 0px 26px 80px rgba(0, 0, 0, 0.2)",
  },
  spaceRadius: {
    xxs: "2px",
    xs: "4px",
    s: "6px",
    xxms: "8px",
    xms: "10px",
    ms: "12px",
    xxm: "16px",
    xm: "20px",
    m: "24px",
    xxml: "32px",
    xml: "40px",
    ml: "48px",
    xxl: "56px",
    xl: "64px",
    l: "72px",
    form: "8px",
  },
  heights: {
    small: "32px",
    medium: "36px",
    large: "40px", // default
  },
  colors: {
    primary: {
      base: "#F66E22",
      "50": "#FEF1E9",
      "100": "#FCD2BA",
      "200": "#FBBC99",
      "300": "#F99E6B",
      "400": "#F88B4E",
      "500": "#F66E22",
      "600": "#E0641F",
      "700": "#AF4E18",
      "800": "#873D13",
      "900": "#672E0E",
    },
    grey: {
      base: "#B1BAD2",
      "50": "#F7F8FB",
      "100": "#E7EAF1",
      "200": "#DBDFEA",
      "300": "#CBD1E1",
      "400": "#C1C8DB",
      "500": "#B1BAD2",
      "600": "#A1A9BF",
      "700": "#7E8495",
      "800": "#616674",
      "900": "#4A4E58",
    },
    red: {
      base: "#F62248",
      "50": "#FEE9ED",
      "100": "#FCBAC6",
      "200": "#FB99AB",
      "300": "#F96B84",
      "400": "#F84E6D",
      "500": "#F62248",
      "600": "#E01F42",
      "700": "#AF1833",
      "800": "#871328",
      "900": "#670E1E",
    },
    green: {
      base: "#1BE76C",
      "50": "#E8FDF0",
      "100": "#B8F8D1",
      "200": "#96F4BB",
      "300": "#66EF9D",
      "400": "#49EC89",
      "500": "#1BE76C",
      "600": "#19D262",
      "700": "#13A44D",
      "800": "#0F7F3B",
      "900": "#0B612D",
    },
    blue: {
      base: "#2284F6",
      "50": "#E9F3FE",
      "100": "#BAD9FC",
      "200": "#99C6FB",
      "300": "#6BADF9",
      "400": "#4E9DF8",
      "500": "#2284F6",
      "600": "#1F78E0",
      "700": "#185EAF",
      "800": "#134987",
      "900": "#0E3767",
    },
    darkBlue: {
      base: "#172034",
      "50": "#E8E9EB",
      "100": "#B7BAC0",
      "200": "#9498A2",
      "300": "#646A77",
      "400": "#454D5D",
      "500": "#172034",
      "600": "#151D2F",
      "700": "#101725",
      "800": "#0D121D",
      "900": "#0A0D16",
    },
    yellow: {
      base: "#F8CC30",
      "50": "#FEFAEA",
      "100": "#FDEFBF",
      "200": "#FCE8A0",
      "300": "#FADD74",
      "400": "#F9D659",
      "500": "#F8CC30",
      "600": "#E2BA2C",
      "700": "#B09122",
      "800": "#88701A",
      "900": "#685614",
    },
    orange: {
      base: "#F66E22",
      "50": "#FEF1E9",
      "100": "#FCD2BA",
      "200": "#FBBC99",
      "300": "#F99E6B",
      "400": "#F88B4E",
      "500": "#F66E22",
      "600": "#E0641F",
      "700": "#AF4E18",
      "800": "#873D13",
      "900": "#672E0E",
    },
    white: {
      base: "#ffffff",
      "50": "rgba(255, 255, 255, 0.02)",
      "100": "rgba(255, 255, 255, 0.04)",
      "200": "rgba(255, 255, 255, 0.06)",
      "300": "rgba(255, 255, 255, 0.08)",
      "400": "rgba(255, 255, 255, 0.1)",
      "500": "rgba(255, 255, 255, 0.2)",
      "600": "rgba(255, 255, 255, 0.4)",
      "700": "rgba(255, 255, 255, 0.6)",
      "800": "rgba(255, 255, 255, 0.8)",
      "900": "#ffffff",
    },
    black: {
      base: "#212427",
      "50": "rgba(33, 36, 39, 0.02)",
      "100": "rgba(33, 36, 39, 0.04)",
      "200": "rgba(33, 36, 39, 0.06)",
      "300": "rgba(33, 36, 39, 0.08)",
      "400": "rgba(33, 36, 39, 0.1)",
      "500": "rgba(33, 36, 39, 0.2)",
      "600": "rgba(33, 36, 39, 0.4)",
      "700": "rgba(33, 36, 39, 0.6)",
      "800": "rgba(33, 36, 39, 0.8)",
      "900": "#212427",
    },
    background: "#F9F9F9",
    text: "#616674",
    headlines: "#172034",
  },
  typography: {
    font: "Inter",
    light: "300",
    regular: "400",
    medium: "500",
    bold: "600",
    h1: rem(32),
    h2: rem(28),
    h3: rem(24),
    h4: rem(20),
    h5: rem(18),
    h6: rem(16),
  },
  breakpoints: {
    up,
    down,
    between,
    only,
  },
  zIndex: {
    lightbox: "8",
    dock: "7",
    modal: "6",
    sidebar: "5",
    popouts: "4",
    stickyTop: "2",
    stickyFooter: "2",
    textEditorBar: "1",
    layout: "1",
  },
  layout: {
    background: "#FCFCFC",
    collapsedWidth: "80px",
    expandedWidth: "260px",
    contentBottomPadding: "24px",
    topbarHeight: "57px",
    topbarSpacer: "16px",
  },
};

export default theme;
