import styled from "styled-components";
import { spacing } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

const AvatarTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(12)};
  font-weight: 400;
  ${spacing};

  .content {
    .main {
      font-size: ${rem(14)};
      font-weight: 500;
    }
  }
`;

export default AvatarTextWrapper;
