import styled from "styled-components";
import { togglerBgStyles } from "../../../styles/Theme/common";

import AvatarWrapper from "../../avatar/avatar.styles";
import Description from "../../description/description.styles";
import Title from "../../title/title.styles";

import rem from "../../../utils/rem";

const TopbarWrapper = styled.header`
  height: ${({ theme }) => theme.layout.topbarHeight};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0 24px;
  margin-bottom: ${({ theme }) => theme.layout.topbarSpacer};

  .user-logout-wrapper {
    padding: 8px;
    border-top: 1px solid ${({ theme }) => theme.colors.grey[300] || "#e6ebf0"};

    svg {
      color: ${({ theme }) => theme.colors.red.base};
    }

    &:first-child {
      border-top: none;
    }
  }

  .user-menu {
    padding: 8px;
  }

  .user-text {
    margin-right: 15px;

    ${Title} {
      color: ${({ theme }) => theme.colors.grey[500]};
      font-size: ${rem(14)};
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${Description} {
      color: ${({ theme }) => theme.colors.grey[500]};
      font-size: ${rem(10)};
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      display: none;
    }
  }

  .user-actions {
    display: flex;
    align-items: center;
    padding: 5px 7px;
    border-radius: 8px;
    cursor: pointer;
    ${togglerBgStyles()};

    ${({ theme }) => theme.breakpoints.down("md")} {
      > ${AvatarWrapper} {
        margin-right: 0px;
      }
    }

    .caret {
      font-size: ${rem(14)};
      color: ${({ theme }) => theme.colors.grey[500]};
      transition: transform 0.2s ease;

      ${({ theme }) => theme.breakpoints.down("md")} {
        display: none;
      }
    }

    &.open {
      .caret {
        transform: rotate(-180deg);
      }
    }
  }

  .single-action-wrapper {
    margin-right: 15px;
  }

  .single-action {
    display: inline-flex;
    align-items: center;
    padding: 5px;
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.grey[500]};
    cursor: pointer;
    ${togglerBgStyles()};

    > span {
      margin-left: 8px;
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .title-wrapper {
    margin-right: 15px;
    display: flex;
    align-items: center;

    .mobile-hamburger {
      display: none;
      color: #ffffff;
      margin-right: 12px;
      font-size: 1.5rem;
      align-items: center;
      cursor: pointer;
    }

    ${({ theme }) => theme.breakpoints.down("md")} {
      .mobile-hamburger {
        display: inline-flex;
      }

      ${Title} {
        > svg {
          display: none;
        }
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0 15px;
  }
`;

export default TopbarWrapper;
