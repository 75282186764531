import React, { lazy, useEffect, useState, Fragment, useCallback } from "react";

import ThemeProvider from "shared/providers/styled-components/theme.provider";
import ToastProvider from "shared/providers/toast/toast.provider";
import { Outlet, Route, Routes } from "shared/providers/router/router.package";

import { Helmet } from "shared/providers/helmet/helmet.package";

import { districtVar } from "../../common/apollo/vars/district.var";

import withSuspense from "shared/hoc/with-suspense/with-suspense.hoc";
import NotFound from "shared/components/not-found/not-found.component";
import Spinner from "shared/components/spinner/spinner.component";

import Layout from "../../containers/layout/layout.container";
import ProtectedRoute from "../../hoc/protected-route/protected-route.hoc";
import SchoolProtectedRoute from "../../hoc/school-protected-route/school-protected-route.hoc";
import IntercomWrapper from "../../hoc/intercom-wrapper/intercom-wrapper.hoc";

import Dock from "../dock/dock.component";

import { setLocalStorageItem } from "shared/utils/local-storage";
import getCurrentDomain from "shared/utils/get-current-domain";

import { useValidateDistrictUrlQuery } from "../../generated/graphql";

import defaultTheme from "shared/styles/Theme";
import { WEB_AUTH_TOKEN, WEB_AUTH_SCHOOL } from "shared/common/data/constants";

import GlobalStyles from "shared/styles";
import GlobalModals from "./global-modals.partial";

const Login = withSuspense(
  lazy(() => import("../../pages/login/login.page")),
  false,
  "100vh"
);

const DistrictSelector = withSuspense(
  lazy(() => import("../../pages/district-selector/district-selector.page")),
  false,
  "100vh"
);

const SchoolSelector = withSuspense(
  lazy(() => import("../../pages/school-selector/school-selector.page")),
  false,
  "100vh"
);

const ForgottenPassword = withSuspense(
  lazy(() => import("../../pages/forgotten-password/forgotten.page")),
  false,
  "100vh"
);

const ResetPassword = withSuspense(
  lazy(() => import("../../pages/reset-password/reset.page")),
  false,
  "100vh"
);

const Dashboard = withSuspense(
  lazy(() => import("../../pages/dashboard/dashboard.page")),
  false,
  "full-layout"
);

const ClassesRoutes = withSuspense(
  lazy(() => import("../../pages/classes/classes.routes")),
  false,
  "full-layout"
);

const Users = withSuspense(
  lazy(() => import("../../pages/users/users.page")),
  false,
  "full-layout"
);

const Anecdotals = withSuspense(
  lazy(() => import("../../pages/anecdotals/anecdotals.page")),
  false,
  "full-layout"
);

const SchoolAdminRoutes = withSuspense(
  lazy(() => import("../../pages/school-admin/school-admin.routes")),
  false,
  "full-layout"
);

const Reporting = withSuspense(
  lazy(() => import("../../pages/reporting/reporting.page")),
  false,
  "full-layout"
);

const DataSync = withSuspense(
  lazy(() => import("../../pages/data-sync/data-sync.routes")),
  false,
  "full-layout"
);

const CleverOAuthLogin = withSuspense(
  lazy(() => import("../../pages/clever-oauth-login/clever-oauth-login.page")),
  false,
  "full-layout"
);

const GoogleClassroomSync = withSuspense(
  lazy(() => import("../../pages/google-classroom-sync/google-classroom-sync.page")),
  false,
  "full-layout"
);

const GoogleOAuthLogin = withSuspense(
  lazy(() => import("../../pages/google-oauth-login/google-oauth-login.page")),
  false,
  "full-layout"
);

const App: React.FC = () => {
  const [initLoaded, setInitLoaded] = useState(false);
  const [domainLoaded, setDomainLoaded] = useState(false);
  const [error, setError] = useState("");

  const { data } = useValidateDistrictUrlQuery({
    fetchPolicy: "no-cache",
    variables: {
      url: getCurrentDomain(),
    },
    onCompleted: ({ validateDistrictURL }) => {
      /**
       * For default URL it will return ID: null
       * If URL does not exist it will return Error
       * If URL is deleted/inactive it will return Error
       * If API does not throw Error we assume its either Default or Custom building
       * So we just need to check if it has ID and set districtVar, otherwise proceed with defaults
       */

      if (validateDistrictURL.id) {
        districtVar({
          id: validateDistrictURL.id,
          name: validateDistrictURL.name || "",
          isDeleted: false,
          timezone: validateDistrictURL.timezone || "America/New_York",
          url: getCurrentDomain(),
          logo: validateDistrictURL.logo || null,
          background: validateDistrictURL.background || null,
          favicon: validateDistrictURL.favicon || null,
        });
      }

      setDomainLoaded(true);
      setError("");
    },
    onError: (err) => {
      console.log(err);

      setDomainLoaded(true);
      setError(err.message);
    },
  });

  useEffect(() => {
    if (domainLoaded) {
      if (data?.validateDistrictURL.id) {
        const queryString = window.location.search;

        if (queryString) {
          const urlParams = new URLSearchParams(queryString),
            loginToken = urlParams.get("loginToken"),
            loginSchoolId = urlParams.get("loginSchoolId");

          if (loginToken) {
            setLocalStorageItem(WEB_AUTH_TOKEN, loginToken);
          }

          if (loginSchoolId) {
            setLocalStorageItem(WEB_AUTH_SCHOOL, loginSchoolId);
          }
        }
      }

      setInitLoaded(true);
    }
  }, [data?.validateDistrictURL.id, domainLoaded]);

  const goToGlobalURL = useCallback(
    () => window.open(process.env.REACT_APP_GLOBAL_URL, "_self"),
    []
  );

  if (!initLoaded || !domainLoaded) {
    return <Spinner $height="100vh" />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <ToastProvider />

      {error ? (
        <NotFound
          title="District Validation Error"
          text={error}
          buttonText="Go to Global Portal"
          buttonOnClick={goToGlobalURL}
        />
      ) : (
        <Fragment>
          {data?.validateDistrictURL.favicon?.url ? (
            <Helmet>
              <link
                rel="icon"
                href={data.validateDistrictURL.favicon.url}
                type="image/png"
              />
            </Helmet>
          ) : null}

          <Routes>
            {["/", "/login"].map((path) => (
              <Route key={path} path={path} element={<Login />} />
            ))}

            <Route path="/district-selector/:token" element={<DistrictSelector />} />

            <Route path="/oauth/google-login" element={<GoogleOAuthLogin />} />

            <Route path="/oauth/clever-login" element={<CleverOAuthLogin />} />

            {data?.validateDistrictURL.id ? (
              <Fragment>
                <Route path="/forgotten-password" element={<ForgottenPassword />} />

                <Route path="/reset-password/:code" element={<ResetPassword />} />

                <Route
                  element={
                    <ProtectedRoute>
                      <IntercomWrapper>
                        <Outlet />
                      </IntercomWrapper>
                    </ProtectedRoute>
                  }
                >
                  <Route path="/school-selector" element={<SchoolSelector />} />

                  <Route
                    element={
                      <SchoolProtectedRoute>
                        <Layout />

                        <GlobalModals />

                        <Dock />
                      </SchoolProtectedRoute>
                    }
                  >
                    {["/dashboard", `/m/:type`, `/m/:type/:id`].map((path) => (
                      <Route key={path} path={path} element={<Dashboard />} />
                    ))}

                    <Route path="/classes/*" element={<ClassesRoutes />} />

                    <Route path="/users" element={<Users />} />

                    <Route path="/anecdotals" element={<Anecdotals />} />

                    <Route path="/reporting" element={<Reporting />} />

                    <Route path="/data-sync/*" element={<DataSync />} />

                    <Route
                      path="/oauth/google-classroom"
                      element={<GoogleClassroomSync />}
                    />

                    <Route path="school-admin/*" element={<SchoolAdminRoutes />} />
                  </Route>
                </Route>
              </Fragment>
            ) : null}

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Fragment>
      )}
    </ThemeProvider>
  );
};

export default App;
