import { makeVar } from "shared/providers/apollo/apollo.package";
import { MeUserAccessFieldsFragment } from "../../../generated/graphql";

/**
 * Global flag that indicates is school being loading
 */
export const schoolAccessLoadingVar = makeVar(false);

/**
 * Store current data about current school and permissions of logged user in it
 */
export const schoolAccessVar = makeVar<MeUserAccessFieldsFragment | null>(null);

export const resetSchoolAccessVar = () => schoolAccessVar(null);
