import React, { useEffect } from "react";

/**
 * Control click outside the ref
 * @param ref target which we control outside click
 * @param handler ideally useCallback function handling our outside click
 */

const useOnClickOutside = (
  ref: React.RefObject<HTMLElement>,
  handler: () => void,
  capture = true,
  skipSelectors?: string[]
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // We don't want to close our modals for example if user clicks on Toaster
      if ((event.target as Element).closest(".Toastify")) {
        return;
      }

      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      let shouldSkip = false;
      if (skipSelectors?.length) {
        for (const singleSelector of skipSelectors) {
          if ((event.target as Element).closest(singleSelector)) {
            shouldSkip = true;
            break;
          }
        }
      }

      if (shouldSkip) {
        return;
      }

      handler();
    };

    document.addEventListener("mousedown", listener, capture);
    document.addEventListener("touchstart", listener, capture);
    return () => {
      document.removeEventListener("mousedown", listener, capture);
      document.removeEventListener("touchstart", listener, capture);
    };
  }, [ref, handler, skipSelectors, capture]);
};

export default useOnClickOutside;
