import React, { useCallback, useMemo, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTheme } from "styled-components";
import { Waypoint } from "react-waypoint";

import useOnClickOutside from "../../../hooks/useOutside.hook";
import { useMediaQuery } from "react-responsive";

import DropdownMenu from "../../dropdown-menu/dropdown-menu.component";
import AvatarText from "../../../containers/avatar-text/avatar-text.container";

import SideBarWrapper from "./sidebar.styles";

import {
  TbBuilding,
  TbChevronDown,
  TbLogout,
  TbLock,
  TbLockOff,
  TbX,
} from "react-icons/tb";

import {
  ISidebarProps,
  ISidebarRoute,
  ISidebarSwitcherItem,
} from "./sidebar.interfaces";
import { BREAKPOINTS } from "../../../common/data/constants";

import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { ReactComponent as LogoOnly } from "../../../assets/logo-only.svg";
import StandardOption from "../../common/standard-option/standard-option.component";

const SideBar: React.FC<ISidebarProps> = ({
  pages,
  secondaryPages,
  expanded,
  setExpanded,
  onLogout,
  switcher,
  switcherItems,
  secondarySwitcherItems,
  fetchMoreSwitcherItems,
}) => {
  const { layout } = useTheme();
  const titleRef = useRef<HTMLDivElement>(null);
  const [switcherState, setSwitcherState] = useState<HTMLDivElement | null>(null);

  const [switcherOpened, setSwitcherOpened] = useState(false);

  const closeSwitcher = useCallback(() => setSwitcherOpened(false), []);

  const openSwitcher = useCallback(() => setSwitcherOpened(true), []);

  const closeSwitcherOutside = useCallback(
    () => (switcherOpened ? setSwitcherOpened(false) : null),
    [switcherOpened]
  );

  useOnClickOutside(titleRef, closeSwitcherOutside);

  const renderSwitcherItems = useCallback(
    (items: ISidebarSwitcherItem[], fetchMore?: (() => void) | null) =>
      items.map(({ id, name, logo, icon, onClick, closeOnClick }) => {
        if (fetchMore) {
          return (
            <Waypoint onEnter={fetchMore} key={id}>
              <StandardOption
                as="li"
                id={id}
                text={name}
                onClick={() => {
                  if (onClick) {
                    onClick(id);
                  }

                  if (closeOnClick) {
                    closeSwitcher();
                  }
                }}
                left="avatar"
                avatarContent={
                  logo ? (
                    <img src={logo} alt="Logo" />
                  ) : icon ? (
                    React.createElement(icon)
                  ) : null
                }
                avatarDefaultIcon="building"
                avatarProps={{
                  $size: "m",
                  $bgColor: "grey",
                  $borderColor: "grey",
                  $color: "grey",
                }}
                active={id === switcher.id}
                disabled={id === switcher.id}
                $spacing={{
                  mb: "2px",
                }}
              />
            </Waypoint>
          );
        }

        return (
          <StandardOption
            as="li"
            key={id}
            id={id}
            text={name}
            onClick={() => {
              if (onClick) {
                onClick(id);
              }

              if (closeOnClick) {
                closeSwitcher();
              }
            }}
            left="avatar"
            avatarContent={
              logo ? (
                <img src={logo} alt="Logo" />
              ) : icon ? (
                React.createElement(icon)
              ) : null
            }
            avatarDefaultIcon="building"
            avatarProps={{
              $size: "m",
              $bgColor: "grey",
              $borderColor: "grey",
              $color: "grey",
            }}
            active={id === switcher.id}
            disabled={id === switcher.id}
            $spacing={{
              mb: "2px",
            }}
          />
        );
      }),
    [switcher]
  );

  const isMobile = useMediaQuery({
    query: `(max-width: ${parseInt(BREAKPOINTS["md"], 10) - 1}px)`,
  });

  const transformItems = useCallback(
    (items: ISidebarRoute[]) => {
      return items.map(({ icon, name, url, external }, idx) => {
        if (external) {
          return (
            <div className="link-item-wrapper" key={`${url}-${idx}`}>
              <a
                className="link-item"
                href={url}
                target="_blank"
                onClick={() => (isMobile ? setExpanded(false) : null)}
              >
                <>
                  {React.createElement(icon)}
                  <span>{name}</span>
                </>
              </a>
            </div>
          );
        } else {
          return (
            <div className="link-item-wrapper" key={`${url}-${idx}`}>
              <NavLink
                className="link-item"
                to={url}
                onClick={() => (isMobile ? setExpanded(false) : null)}
              >
                <>
                  {React.createElement(icon)}
                  <span>{name}</span>
                </>
              </NavLink>
            </div>
          );
        }
      });
    },
    [isMobile]
  );

  const menuItems = useMemo(() => {
    if (!pages || !pages.length) {
      return null;
    }

    return transformItems(pages);
  }, [pages, transformItems]);

  const secondaryMenuItems = useMemo(() => {
    if (!secondaryPages || !secondaryPages.length) {
      return null;
    }

    return transformItems(secondaryPages);
  }, [secondaryPages, transformItems]);

  return (
    <SideBarWrapper
      className={
        expanded && switcherOpened
          ? "expanded open"
          : expanded
          ? "expanded"
          : switcherOpened
          ? "open"
          : undefined
      }
    >
      <div className="title-wrapper" ref={titleRef}>
        <div className="title-wrapper__closer">
          <TbX onClick={() => setExpanded(false)} />
        </div>

        <div
          ref={setSwitcherState}
          className={`title-wrapper__logo${
            switcherItems?.length || secondarySwitcherItems?.length
              ? " hoverable"
              : ""
          }`}
          onClick={
            switcherItems?.length || secondarySwitcherItems?.length
              ? openSwitcher
              : undefined
          }
          tabIndex={-1}
        >
          <AvatarText
            text={switcher.name}
            avatarProps={{
              $size: "m",
              $bgColor: "grey",
              $borderColor: "grey",
              $color: "grey",
            }}
          >
            {switcher.logo ? (
              <img src={switcher.logo} alt={`${switcher.name} logo`} />
            ) : switcher.icon ? (
              React.createElement(switcher.icon)
            ) : (
              <TbBuilding />
            )}
          </AvatarText>

          {switcherItems?.length || secondarySwitcherItems?.length ? (
            <TbChevronDown className="title-wrapper__logo__caret" />
          ) : null}
        </div>

        {switcherItems?.length || secondarySwitcherItems?.length ? (
          <DropdownMenu
            referenceRef={switcherState}
            open={switcherOpened}
            modifiers={{
              placement: "bottom-start",
            }}
            $width={`calc(${layout.expandedWidth} - 32px)`}
            as="div"
          >
            {switcherItems?.length ? (
              <ul className="switcher-list-wrapper is-primary">
                {renderSwitcherItems(switcherItems, fetchMoreSwitcherItems)}
              </ul>
            ) : null}

            {secondarySwitcherItems?.length ? (
              <ul className="switcher-list-wrapper">
                {renderSwitcherItems(secondarySwitcherItems)}
              </ul>
            ) : null}
          </DropdownMenu>
        ) : null}
      </div>

      {menuItems?.length ? <div className="body-wrapper">{menuItems}</div> : null}

      <div className="footer-wrapper">
        {secondaryMenuItems}

        <div className="link-item-wrapper hide-under-md">
          <div className="link-item" onClick={() => setExpanded(!expanded)}>
            {expanded ? <TbLockOff /> : <TbLock />}
            <span>{expanded ? "Unlock" : "Lock"} menu</span>
          </div>
        </div>

        <div className="link-item-wrapper">
          <div className="link-item" onClick={onLogout}>
            <TbLogout />
            <span>Logout</span>
          </div>
        </div>

        <div className="footer-wrapper__logo">
          <Logo className="footer-wrapper__logo__big" />
          <LogoOnly className="footer-wrapper__logo__default" />
        </div>
      </div>
    </SideBarWrapper>
  );
};

export default SideBar;
