import ModalWrapper from "../../components/modal/modal.styles";
import Spinner from "../../components/spinner/spinner.component";

import { ISpinnerModalProps } from "./spinner-modal.interfaces";

const SpinnerModal = ({
  $width = "auto",
  $height,
  $borderRadius,
  $spacing = {
    p: "24px",
  },
}: ISpinnerModalProps) => {
  return (
    <ModalWrapper $width={$width} $height={$height} $borderRadius={$borderRadius}>
      <div className="modal__content-wrapper">
        <Spinner $spacing={$spacing} />
      </div>
    </ModalWrapper>
  );
};

export default SpinnerModal;
