import styled, {
  keyframes,
} from "shared/providers/styled-components/styled-components.package";
import { rollIn } from "shared/providers/animations/animations.package";
import rem from "shared/utils/rem";

const rollAnimation = keyframes`${rollIn}`;

const DockWrapper = styled.ul`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: ${({ theme }) => theme.layout.contentBottomPadding};
  z-index: ${({ theme }) => theme.zIndex.dock};

  > li {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.grey[100]}
      ${({ theme }) => theme.colors.grey[100]} transparent;
    background-color: #ffffff;
    font-size: ${rem(14)};
    transition: background-color 0.2s ease;
    margin-right: 7px;
    animation: 0.3s ${rollAnimation};
    cursor: pointer;

    > svg {
      display: block;
      margin-left: 7px;
      transition: transform 0.2s ease;
      font-size: ${rem(16)};

      &:hover,
      &:active,
      &:focus {
        transform: scale(1.2);
      }
    }

    &:hover,
    &:active,
    &:focus {
      background-color: ${({ theme }) => theme.colors.primary.base};
      color: #ffffff;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DockDropdown = styled.div`
  .dock-dropdown-indicator {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    right: ${({ theme }) => theme.layout.contentBottomPadding};
    padding: 8px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.grey[300]}
      ${({ theme }) => theme.colors.grey[300]} transparent;
    background-color: #ffffff;
    font-size: 14px;
    transition: background-color 0.2s ease;
    animation: 0.3s ${rollAnimation};
    cursor: pointer;
    z-index: ${({ theme }) => theme.zIndex.dock};

    > svg {
      margin-left: 8px;
      font-size: 16px;
      transition: transform 0.2s ease;
      transform: rotate(-180deg);

      ${({ theme }) => theme.breakpoints.down("md")} {
        display: none;
      }
    }

    &.open {
      > svg {
        transform: rotate(0deg);
      }
    }
  }

  .dock-dropdown-closer {
    margin-left: auto;
    margin-right: 0;
  }
`;

export default DockWrapper;
