import styled from "styled-components";
import { flex, spacing } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

import { IExternalOptionProps } from "./external-link.interfaces";

const ExternalLink = styled.a<IExternalOptionProps>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme, $color }) => $color || theme.colors.green.base};
  font-size: ${rem(14)};
  cursor: pointer;
  ${flex};
  ${spacing};

  > svg {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.grey[600]};
    margin-left: 8px;
  }
`;

export default ExternalLink;
