import styled, { keyframes } from "styled-components";
import { fadeIn } from "react-animations";

import { IErrorProps } from "./error.interfaces";
import { spacing } from "../../../styles/Theme/styled-options";

import rem from "../../../utils/rem";

const fadeInAnimation = keyframes`${fadeIn}`;

const ErrorWrapper = styled.div<IErrorProps>`
  margin-left: 2px;
  margin-top: 4px;
  display: flex;
  color: ${({ theme }) => theme.colors.red.base};
  font-size: ${rem(12)};
  line-height: 1.2;
  animation: 0.3s ${fadeInAnimation};
  word-break: break-all;
  ${spacing};

  > svg {
    margin-right: 8px;
    margin-top: 1px;
    flex-shrink: 0;
  }
`;

export default ErrorWrapper;
