import { ChangeEvent, useCallback, useRef } from "react";

import AvatarWrapper, { AvatarPreview } from "./avatar.styles";

import { IAvatarProps } from "./avatar.interfaces";
import { TbTrash } from "react-icons/tb";

const Avatar = ({
  onFileChange,
  onDelete,
  accept,
  $cursor,
  $bg,
  $bgColor,
  $borderColor,
  $color,
  $size,
  $hasBoxShadow,
  children,
  ...props
}: IAvatarProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (onFileChange && e.target.files?.length) {
        const file = e.target.files[0];

        onFileChange(file);
      }
    },
    [onFileChange]
  );

  return (
    <AvatarWrapper {...props}>
      <input
        type="file"
        accept={accept || "image/png, image/jpeg"}
        ref={inputRef}
        onChange={handleFileChange}
      />

      <AvatarPreview
        $hasBoxShadow={$hasBoxShadow}
        $cursor={$cursor}
        $bg={$bg}
        $bgColor={$bgColor}
        $borderColor={$borderColor}
        $color={$color}
        $size={$size}
        onClick={() => {
          if (onFileChange) {
            inputRef.current?.click();
          }
        }}
      >
        {children}
      </AvatarPreview>

      {onDelete ? (
        <span className="deleter" onClick={() => onDelete()}>
          <TbTrash />
        </span>
      ) : null}
    </AvatarWrapper>
  );
};

export default Avatar;
