export const WEB_AUTH_TOKEN = "practiceWebAuthToken";
export const WEB_AUTH_SCHOOL = "practiceWebAuthSchool";

/**
 * District Admin
 */

export const ADMIN_AUTH_TOKEN = "practiceAdminAuthToken";

/**
 * Breakpoints for responsive design
 */

export const BREAKPOINTS = {
  sm: "576px",
  md: "768px",
  lg: "992px", // default
  xl: "1200px",
  xxl: "1600px",
};

/**
 * Different types for DND depending on the usage (table, menu manager, etc..)
 */

export const DndTypes = {
  COLUMNSELECTOR: "columnSelector",
};
