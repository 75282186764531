import styled from "styled-components";
import {
  dropdownItemActiveStyles,
  dropdownItemDisabledStyles,
  dropdownItemHoverStyles,
  dropdownItemStyles,
} from "../../styles/Theme/common";
import { spacing } from "../../styles/Theme/styled-options";

import { IDropdownMenuStyles } from "./dropdown-menu.interfaces";

export const DropdownMenuListItem = styled.li`
  ${dropdownItemStyles};

  &.active {
    ${dropdownItemActiveStyles};
  }

  &:hover:not(.disabled),
  &:active:not(.disabled),
  &:focus:not(.disabled) {
    ${dropdownItemHoverStyles};
  }

  &.disabled {
    ${dropdownItemDisabledStyles};
  }
`;

const DropdownMenuWrapper = styled.ul<IDropdownMenuStyles>`
  background-color: #ffffff;
  border-radius: ${({ theme }) => theme.spaceRadius.xxms};
  border: 1px solid ${({ theme }) => theme.colors.grey[100]};
  width: ${({ $width }) => $width || "200px"};
  max-width: 100%;
  display: ${({ open }) => (open ? "block" : "none")};
  box-shadow: ${({ theme }) => theme.boxShadow.dropdown};
  z-index: ${({ theme }) => theme.zIndex.popouts};
  ${spacing};
`;

export default DropdownMenuWrapper;
