import { InMemoryCache } from "shared/providers/apollo/apollo.package";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getClasses: {
          keyArgs: ["search", "schoolId", "deleted", "markingPeriodId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getClassesByUser: {
          keyArgs: ["search", "schoolId", "userId", "deleted", "markingPeriodId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getUsers: {
          keyArgs: ["search", "schoolId", "role", "status"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getTerms: {
          keyArgs: ["schoolId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getAnecdotalTypes: {
          keyArgs: ["schoolId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getMarkingPeriods: {
          keyArgs: ["schoolId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getGradebooks: {
          keyArgs: ["schoolId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getTags: {
          keyArgs: ["schoolId", "search", "deleted"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getReports: {
          keyArgs: ["schoolId"],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getAnecdotals: {
          keyArgs: [
            "schoolId",
            "search",
            "from",
            "to",
            "userIds",
            "referToIds",
            "createdByIds",
            "anecdotalTypeIds",
            "severities",
          ],
          merge: (existing, incoming, { args }) => {
            if (args && !args.after) {
              return incoming;
            }

            return {
              pagination: incoming.pagination,
              data: existing?.data?.length
                ? [...existing.data, ...incoming.data]
                : incoming.data,
            };
          },
        },
        getClassAttendance: {
          merge: false,
        },
      },
    },
    FileModel: {
      keyFields: false,
    },
    IntegrationSetupModel: {
      keyFields: false,
    },
    UserGrade: {
      keyFields: ["assignmentId", "userId"],
    },
    ClassGoogleModel: {
      keyFields: false,
    },
  },
});

export default cache;
