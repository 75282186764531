import { forwardRef } from "react";
import { TbRotateClockwise2 } from "react-icons/tb";
import ButtonWrapper from "./button.styles";

import { IButtonProps } from "./button.interfaces";

const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      type = "button",
      variant = "primary",
      $size = "large",
      disabled,
      isBlock,
      isLoading,
      prependElement,
      appendElement,
      hideTextMobile,
      children,
      ...rest
    },
    ref
  ) => {
    return (
      <ButtonWrapper
        ref={ref}
        type={type}
        variant={variant}
        $size={$size}
        isBlock={!!isBlock}
        disabled={!!disabled || !!isLoading}
        hideTextMobile={hideTextMobile}
        {...rest}
      >
        {isLoading ? (
          <span>
            <TbRotateClockwise2 className="rotate-animation" />
          </span>
        ) : prependElement ? (
          <span>{prependElement}</span>
        ) : null}

        {children && <span className="button-text-wrapper">{children}</span>}

        {appendElement && <span>{appendElement}</span>}
      </ButtonWrapper>
    );
  }
);

export default Button;
