import styled from "styled-components";
import rem from "../../../utils/rem";

import { ISpace } from "../../../common/interfaces/theme-options.interfaces";
import CheckboxWrapper from "../../form/checkbox/checkbox.styles";
import { spacing } from "../../../styles/Theme/styled-options";

const StandardOptionWrapper = styled.li<ISpace>`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: ${rem(12)};
  font-weight: 400;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.grey[700]};
  border-radius: ${({ theme }) => theme.spaceRadius.xxms};
  padding: 6px 8px;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  position: relative;
  cursor: pointer;
  ${spacing};

  .left-wrapper {
    margin-right: 8px;

    &--icon {
      svg {
        display: block;
      }
    }
  }

  .content {
    .main {
      font-size: ${rem(14)};
      font-weight: 500;
    }
  }

  .right-wrapper {
    margin-left: auto;

    &--icon {
      svg {
        display: block;
      }
    }
  }

  &.active,
  &:hover:not(.disabled) {
    color: ${({ theme }) => theme.colors.darkBlue[500]};

    .right-wrapper {
      &--text {
        color: ${({ theme }) => theme.colors.grey[700]};
      }
    }
  }

  &:hover:not(.disabled) {
    background-color: ${({ theme }) => theme.colors.grey[100]};

    .left-wrapper,
    .right-wrapper {
      ${CheckboxWrapper} {
        .checkbox-element {
          border-color: ${({ theme }) => theme.colors.primary.base};
        }
      }
    }
  }

  &.active {
    &:not(.no-marker) {
      .right-wrapper {
        &--default {
          height: 15px;
          width: 15px;
          border-width: 1px;
          border-style: solid;
          border-color: ${({ theme }) => theme.colors.primary.base};
          border-radius: 50%;
          position: relative;

          &::after {
            content: "";
            height: 7px;
            width: 3px;
            border-width: 0 1px 1px 0;
            border-style: solid;
            border-color: ${({ theme }) => theme.colors.primary.base};
            position: absolute;
            top: calc(50% - 1px);
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }
      }
    }

    .right-wrapper {
      &--icon {
        color: ${({ theme }) => theme.colors.primary.base};
      }
    }
  }

  &.disabled {
    background-color: ${({ theme }) => theme.colors.grey[100]};
    color: ${({ theme }) => theme.colors.grey[600]};
    cursor: not-allowed;

    .right-wrapper {
      &--icon,
      &--text {
        color: ${({ theme }) => theme.colors.grey[600]};
      }
    }

    &:not(.no-marker) {
      .right-wrapper {
        &--default {
          border-color: ${({ theme }) => theme.colors.grey[600]};

          &::after {
            border-color: ${({ theme }) => theme.colors.grey[600]};
          }
        }
      }
    }
  }
`;

export default StandardOptionWrapper;
