import styled, { css } from "styled-components";
import { transparentize } from "polished";

import { AvatarPreview } from "../../avatar/avatar.styles";
import ColorOptionWrapper from "../../common/color-option/color-option.styles";
import Dot from "../../dot/dot.styles";
import StandardOptionWrapper from "../../common/standard-option/standard-option.styles";

import { ISelectStyles } from "./select.interfaces";
import { flex, spacing } from "../../../styles/Theme/styled-options";
import {
  dropdownItemDisabledStyles,
  dropdownItemHoverStyles,
  dropdownItemActiveStyles,
  dropdownItemStyles,
} from "../../../styles/Theme/common";

import rem from "../../../utils/rem";

export const SELECT_CLASS_NAME = "form-custom-select-element";

const colorOptionStyles = css`
  ${ColorOptionWrapper} ${Dot} {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const SelectWrapper = styled.div<ISelectStyles>`
  position: relative;
  padding-bottom: ${({ theme }) => theme.spaceRadius.form};
  min-width: ${({ $minWidth }) => $minWidth};
  width: ${({ $width }) => $width};
  max-width: ${({ $maxWidth }) => $maxWidth || "100%"};
  ${spacing};
  ${flex};

  .${SELECT_CLASS_NAME} {
    &__placeholder {
      color: ${({ theme, variant, hasError }) =>
        hasError
          ? theme.colors.red.base
          : variant === "transparent"
          ? theme.colors.grey[600]
          : theme.colors.grey[700]};
      transition: color 0.2s ease;

      &:first-child {
        margin-left: 0;
      }
    }

    &__input {
      &-container {
        margin: 0;
        padding: 0;
        color: ${({ theme, variant, $disabled }) =>
          $disabled
            ? theme.colors.grey[600]
            : variant === "transparent"
            ? "#ffffff"
            : theme.colors.darkBlue[500]};
      }
    }

    &__value-container {
      padding: 0;

      ${colorOptionStyles};

      ${AvatarPreview} {
        width: 16px;
        height: 16px;
        font-size: ${rem(12)};
      }

      ${StandardOptionWrapper} {
        padding: 0;
        background-color: transparent;
        color: inherit;

        /* Defaults. If you want to remove other items like icon or avatar hide through props of StandardOption based on where option is used in (option vs value) */
        .left-wrapper--checkbox,
        .right-wrapper,
        .content .description {
          display: none;
        }

        .content .main {
          font-weight: 400;
        }
      }
    }

    &__single-value {
      color: ${({ theme, variant, $disabled }) =>
        $disabled
          ? theme.colors.grey[600]
          : variant === "transparent"
          ? "#ffffff"
          : theme.colors.darkBlue[500]};
      font-weight: 400;

      &:first-child {
        margin-left: 0;
      }
    }

    &__indicators {
      padding-left: 7px;
    }

    &__indicator {
      color: ${({ theme, variant, hasError, $disabled }) =>
        $disabled
          ? theme.colors.grey[600]
          : hasError
          ? theme.colors.red.base
          : variant === "transparent"
          ? "#ffffff"
          : theme.colors.grey[700]};
      padding: 0 7px 0 0;
      font-size: ${rem(16)};
      transition: transform 0.2s ease;

      &-separator {
        display: none;
      }

      > svg {
        display: block;
        width: 16px;
        height: 16px;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &__loading-indicator {
      font-size: ${rem(4)};
    }

    &__clear-indicator {
      color: ${({ theme }) => theme.colors.red.base};
      opacity: 0.6;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        color: ${({ theme }) => theme.colors.red.base};
        opacity: 1;
      }
    }

    &__menu {
      border: 1px solid ${({ theme }) => theme.colors.grey[100]};
      box-shadow: ${({ theme }) => theme.boxShadow.dropdown};
      border-radius: ${({ theme }) => theme.spaceRadius.xxms};

      &-list {
        padding: 8px;
      }

      &-portal {
        z-index: ${({ theme }) => theme.zIndex.popouts};
      }
    }

    &__option {
      background-color: transparent;

      ${({ useMinimalOptionStyles, theme }) =>
        useMinimalOptionStyles
          ? css`
              padding: 0;
              margin-bottom: 2px;
              cursor: pointer;
              color: ${theme.colors.grey[700]};

              &:hover:not(.${SELECT_CLASS_NAME}__option--is-disabled),
              &:active:not(.${SELECT_CLASS_NAME}__option--is-disabled),
              &:focus:not(.${SELECT_CLASS_NAME}__option--is-disabled) {
                background-color: transparent;
                color: ${theme.colors.darkBlue[500]};
              }

              &--is-disabled {
                color: ${theme.colors.grey[600]};
                cursor: not-allowed;
              }

              &:last-child {
                margin-bottom: 0;
              }
            `
          : css`
              ${dropdownItemStyles};

              &--is-selected {
                ${dropdownItemActiveStyles};
                ${colorOptionStyles};
              }

              &:hover:not(.${SELECT_CLASS_NAME}__option--is-disabled),
              &:active:not(.${SELECT_CLASS_NAME}__option--is-disabled),
              &:focus:not(.${SELECT_CLASS_NAME}__option--is-disabled) {
                ${dropdownItemHoverStyles};
              }

              &--is-disabled {
                ${dropdownItemDisabledStyles};
              }
            `}
    }

    &__multi-value {
      background-color: #f4f4f4;
      border-radius: 4px;

      &__label {
        font-size: 80%;
      }

      &__remove {
        border-radius: 4px;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
          background-color: ${({ theme }) => theme.colors.red[100]};
          color: ${({ theme }) => theme.colors.red.base};
        }
      }
    }

    &__control {
      width: 100%;
      min-height: ${({ theme, $size }) => theme.heights[$size || "large"]};
      padding: ${({ variant, hasError, $size }) =>
        variant === "preview" && !hasError
          ? "2px 0"
          : $size === "medium"
          ? "2px 9px"
          : $size === "small"
          ? "2px 7px"
          : "2px 11px"};
      background-color: ${({
        variant,
        hasError,
        theme,
        $disabled,
        noDefaultBgBorder,
      }) =>
        $disabled
          ? variant === "preview"
            ? "transparent"
            : variant === "transparent"
            ? "rgba(255, 255, 255, 0.2)"
            : theme.colors.grey[100]
          : variant === "transparent"
          ? hasError
            ? transparentize(0.1, theme.colors.red.base)
            : noDefaultBgBorder
            ? "transparent"
            : "rgba(255, 255, 255, 0.1)"
          : noDefaultBgBorder || variant === "preview"
          ? "transparent"
          : "#ffffff"};
      border-color: ${({ theme, hasError, variant, $disabled, noDefaultBgBorder }) =>
        $disabled
          ? variant === "preview"
            ? "transparent"
            : variant === "transparent"
            ? "rgba(255, 255, 255, 0.2)"
            : theme.colors.grey[100]
          : hasError
          ? variant === "transparent"
            ? transparentize(0.1, theme.colors.red.base)
            : theme.colors.red.base
          : noDefaultBgBorder || variant === "preview"
          ? "transparent"
          : variant === "transparent"
          ? "rgba(255, 255, 255, 0.1)"
          : theme.colors.grey[100]};
      border-radius: ${({ theme }) => theme.spaceRadius.xxms};
      border-width: 1px;
      border-style: solid;
      color: ${({ theme, variant, $disabled }) =>
        $disabled
          ? theme.colors.grey[600]
          : variant === "transparent"
          ? "#ffffff"
          : theme.colors.darkBlue[500]};
      font-size: ${rem(14)};
      line-height: 1.15;
      font-family: ${({ theme }) => theme.typography.font};
      cursor: ${({ $disabled, customCursor }) =>
        $disabled ? "not-allowed" : customCursor || "pointer"};
      box-shadow: none;
      transition: all 0.1s ease;

      ${({
        $disabled,
        theme,
        variant,
        $size,
        $hoverActiveMinWidth,
        disableIndicatorRotation,
      }) =>
        $disabled
          ? undefined
          : css`
              &:hover,
              &--is-focused {
                padding: ${$size === "medium"
                  ? "2px 9px"
                  : $size === "small"
                  ? "2px 7px"
                  : "2px 11px"};

                .${SELECT_CLASS_NAME} {
                  &__placeholder {
                    color: ${variant === "transparent"
                      ? "#ffffff"
                      : theme.colors.grey[700]};
                  }
                }
              }

              &:hover {
                min-width: ${$hoverActiveMinWidth};
                border-color: ${variant === "transparent"
                  ? "rgba(255, 255, 255, 0.2)"
                  : theme.colors.grey[100]};
                background-color: ${variant === "transparent"
                  ? "rgba(255, 255, 255, 0.2)"
                  : theme.colors.grey[50]};

                .${SELECT_CLASS_NAME}__indicator:not(.${SELECT_CLASS_NAME}__clear-indicator) {
                  color: ${variant === "transparent"
                    ? "#ffffff"
                    : theme.colors.grey[700]};
                }
              }

              &--is-focused,
              &--menu-is-open {
                min-width: ${$hoverActiveMinWidth};
                border-color: ${variant === "transparent"
                  ? "rgba(255, 255, 255, 0.1)"
                  : theme.colors.primary.base};
                background-color: ${variant === "transparent"
                  ? "rgba(255, 255, 255, 0.1)"
                  : "#ffffff"};

                .${SELECT_CLASS_NAME}__indicator:not(.${SELECT_CLASS_NAME}__clear-indicator) {
                  color: ${theme.colors.primary.base};
                  transform: ${disableIndicatorRotation
                    ? "none"
                    : "rotate(-180deg)"};
                }
              }
            `}
    }
  }
`;

export default SelectWrapper;
