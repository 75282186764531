import styled from "styled-components";
import { ITitleProps } from "./title.interfaces";

import { spacing, flex, textAlignment } from "../../styles/Theme/styled-options";

const Title = styled.h1<ITitleProps>`
  font-size: ${({ level, theme }) =>
    level
      ? level === 1
        ? theme.typography.h1
        : level === 2
        ? theme.typography.h2
        : level === 3
        ? theme.typography.h3
        : level === 4
        ? theme.typography.h4
        : level === 5
        ? theme.typography.h5
        : theme.typography.h6
      : theme.typography.h1};
  color: ${({ $color, theme }) => $color || theme.colors.headlines};
  ${spacing};
  ${flex};
  ${textAlignment};

  > svg {
    color: ${({ theme, $iconColor }) => $iconColor || theme.colors.primary.base};
    margin-right: 7px;
    flex-shrink: 0;
  }
`;

export default Title;
