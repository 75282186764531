import { Suspense } from "react";

import Spinner from "../../components/spinner/spinner.component";
import SpinnerModal from "../../containers/spinner-modal/spinner-modal.container";

import { IWithSuspense } from "./with-suspense.interfaces";

const withSuspense: IWithSuspense =
  (WrapperComponent, spinnerAsModal, spinnerHeight) => (props) => {
    return (
      <Suspense
        fallback={
          spinnerAsModal ? <SpinnerModal /> : <Spinner $height={spinnerHeight} />
        }
      >
        <WrapperComponent {...props} />
      </Suspense>
    );
  };

export default withSuspense;
