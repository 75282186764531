import { createGlobalStyle } from "styled-components";
import { dropdownItemHoverStyles, dropdownItemStyles } from "./Theme/common";

import rem from "../utils/rem";

export default createGlobalStyle`
    :root {
        --toastify-toast-min-height: auto;
    }

    html, body {
        margin: 0;
        padding: 0;
        outline: 0;
    }

    /* Rem on body because user can set different root (html) value in preferences */
    body {
        color: ${({ theme }) => theme.colors.text};
        font-family: ${({ theme }) => theme.typography.font}, sans-serif;
        font-size: ${rem(14)};
        line-height: 1.3;
        font-weight: 400;
    }

    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
        scroll-behavior: smooth;
    }

    a {
        color: ${({ theme }) => theme.colors.green.base};
        text-decoration: none;
    }

    h1, h2, h3, h4, h5, h6 {
        color: ${({ theme }) => theme.colors.headlines};
        > svg {
            margin-right: 7px;
            flex-shrink: 0;
        }
    }

    h1 {
        font-size: ${({ theme }) => theme.typography.h1};
    }

    h2 {
        font-size: ${({ theme }) => theme.typography.h2};
    }

    h3 {
        font-size: ${({ theme }) => theme.typography.h3};
    }

    h4 {
        font-size: ${({ theme }) => theme.typography.h4};
    }

    h5 {
        font-size: ${({ theme }) => theme.typography.h5};
    }

    h6 {
        font-size: ${({ theme }) => theme.typography.h6};
    }

    strong {
        font-weight: ${({ theme }) => theme.typography.bold};
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    
    ol, ul {
        list-style-position: inside;
    }

    hr {
        border-color: ${({ theme }) => theme.colors.grey[300]};
    }

    code {
        font-family: monospace;
        background-color: ${({ theme }) => theme.colors.grey[300]};
        padding: 3px;
    }

    pre {
        color: #24292e;
        background-color: ${({ theme }) => theme.colors.grey[300]};
        font-family: "ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas,
            "Liberation Mono", monospace;
        padding: 3px;

        code {
            color: inherit;
            padding: 0;
            background: none;
            font-size: 0.8rem;
        }
    }

    small {
        font-size: 80%;
    }

    blockquote {
        border-left: 2px solid #dddddd;
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        color: #aaaaaa;
        font-style: italic;
    }

    .hide-above {
        &-sm {
            ${({ theme }) => theme.breakpoints.up("sm")} {
                display: none !important;
            }
        }

        &-md {
            ${({ theme }) => theme.breakpoints.up("md")} {
                display: none !important;
            }
        }

        &-lg {
            ${({ theme }) => theme.breakpoints.up("lg")} {
                display: none !important;
            }
        }

        &-xl {
            ${({ theme }) => theme.breakpoints.up("xl")} {
                display: none !important;
            }
        }

        &-xxl {
            ${({ theme }) => theme.breakpoints.up("xxl")} {
                display: none !important;
            }
        }
    }

    .hide-under {
        &-sm {
            ${({ theme }) => theme.breakpoints.down("sm")} {
                display: none !important;
            }
        }

        &-md {
            ${({ theme }) => theme.breakpoints.down("md")} {
                display: none !important;
            }
        }

        &-lg {
            ${({ theme }) => theme.breakpoints.down("lg")} {
                display: none !important;
            }
        }

        &-xl {
            ${({ theme }) => theme.breakpoints.down("xl")} {
                display: none !important;
            }
        }

        &-xxl {
            ${({ theme }) => theme.breakpoints.down("xxl")} {
                display: none !important;
            }
        }
    }

    .display-none {
        display: none;
    }

    .display-flex {
        display: flex;

        &--expand {
            flex: 1;
        }

        &--a-center {
            align-items: center;
        }

        &--j-center {
            justify-content: center;
        }

        &--j-between {
            justify-content: space-between;
        }

        &--column {
            flex-direction: column;
        }

        &--wrap {
            flex-wrap: wrap;
        }

        &--stop-auto-width {
            min-width: 0;
        }

        &--stop-auto-height {
            min-height: 0;
        }

        &--stop-shrink {
            flex-shrink: 0;
        }
    }

    .overflow {
        &-hidden {
            overflow: hidden;
        }

        &-auto {
            overflow: auto;
        }
    }

    .pointer {
        cursor: pointer
    }

    .position {
        &-relative {
            position: relative;
        }

        &-absolute {
            position: absolute;
        }

        &-sticky {
            position: sticky;
        }
    }

    .rotate-animation {
        animation: rotate 1.2s linear infinite;

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }
            
            100% {
                transform: rotate(360deg);
            }
        }
    }

    .Toastify {
        &__close-button {
            align-self: center;
        }
        
        &__toast {
            border-left: 3px solid #000000;

            &--success {
                border-color: ${({ theme }) => theme.colors.green.base};
            }
        }
    }

    .contexify {
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors.grey[100]};
        box-shadow: ${({ theme }) => theme.boxShadow.dropdown};
        z-index: ${({ theme }) => theme.zIndex.popouts};
        padding: 0;

        &__separator {
            background-color: ${({ theme }) => theme.colors.grey[100]};
        }
        
        &_item {
            padding-left: 8px;
            padding-right: 8px;

            &:first-child {
                padding-top: 8px;
            }

            &:last-child {
                padding-bottom: 8px;
            }

            &Content {
                ${dropdownItemStyles};
            }

            &:not(.contexify_item-disabled) {
                &:hover,
                &:active,
                &:focus {
                    > .contexify_itemContent {
                        ${dropdownItemHoverStyles};
                    }
                }
            }
        }
    }

    .tooltip-cmp {
        box-shadow: ${({ theme }) => theme.boxShadow.popup};
        max-width: 250px;
        font-weight: normal;
        padding: 10px !important;
    }

    .color {
        &-primary {
            color: ${({ theme }) => theme.colors.primary.base} !important;
        }

        &-grey {
            color: ${({ theme }) => theme.colors.grey.base} !important;
        }

        &-grey-600 {
            color: ${({ theme }) => theme.colors.grey[600]} !important;
        }

        &-grey-700 {
            color: ${({ theme }) => theme.colors.grey[700]} !important;
        }

        &-grey-800 {
            color: ${({ theme }) => theme.colors.grey[800]} !important;
        }

        &-green,
        &-success {
            color: ${({ theme }) => theme.colors.green.base} !important;
        }

        &-red,
        &-error {
            color: ${({ theme }) => theme.colors.red.base} !important;
        }

        &-blue {
            color: ${({ theme }) => theme.colors.blue.base} !important;
        }

        &-dark-blue {
            color: ${({ theme }) => theme.colors.darkBlue.base} !important;
        }

        &-dark-blue-100 {
            color: ${({ theme }) => theme.colors.darkBlue[100]} !important;
        }

        &-dark-blue-800 {
            color: ${({ theme }) => theme.colors.darkBlue[800]} !important;
        }

        &-yellow {
            color: ${({ theme }) => theme.colors.yellow.base} !important;
        }

        &-orange {
            color: ${({ theme }) => theme.colors.orange.base} !important;
        }

        &-text {
            color: ${({ theme }) => theme.colors.text} !important;
        }

        &-headlines {
            color: ${({ theme }) => theme.colors.headlines} !important;
        }

        &-black {
            color: #000000 !important;
        }

        &-white {
            color:#ffffff !important;
        }
    }

    .pr {
        &-0 {
            padding-right: 0;
        }

        &-4 {
            padding-right: 4px;
        }

        &-8 {
            padding-right: 8px;
        }

        &-12 {
            padding-right: 12px;
        }

        &-16 {
            padding-right: 16px;
        }

        &-20 {
            padding-right: 20px;
        }

        &-24 {
            padding-right: 24px;
        }
    }

    .pl {
        &-0 {
            padding-left: 0;
        }

        &-4 {
            padding-left: 4px;
        }

        &-8 {
            padding-left: 8px;
        }

        &-12 {
            padding-left: 12px;
        }

        &-16 {
            padding-left: 16px;
        }

        &-20 {
            padding-left: 20px;
        }

        &-24 {
            padding-left: 24px;
        }
    }

    .pt {
        &-0 {
            padding-top: 0;
        }

        &-4 {
            padding-top: 4px;
        }

        &-8 {
            padding-top: 8px;
        }

        &-12 {
            padding-top: 12px;
        }

        &-16 {
            padding-top: 16px;
        }

        &-20 {
            padding-top: 20px;
        }

        &-24 {
            padding-top: 24px;
        }
    }

    .pb {
        &-0 {
            padding-bottom: 0;
        }

        &-4 {
            padding-bottom: 4px;
        }

        &-8 {
            padding-bottom: 8px;
        }

        &-12 {
            padding-bottom: 12px;
        }

        &-16 {
            padding-bottom: 16px;
        }

        &-20 {
            padding-bottom: 20px;
        }

        &-24 {
            padding-bottom: 24px;
        }
    }

    .mr {
        &-auto {
            margin-right: auto;
        }

        &-2 {
            margin-right: 2px;
        }

        &-4 {
            margin-right: 4px;
        }

        &-8 {
            margin-right: 8px;
        }

        &-12 {
            margin-right: 12px;
        }

        &-16 {
            margin-right: 16px;
        }

        &-20 {
            margin-right: 20px;
        }

        &-24 {
            margin-right: 24px;
        }
    }

    .ml {
        &-auto {
            margin-left: auto;
        }

        &-2 {
            margin-left: 2px;
        }

        &-4 {
            margin-left: 4px;
        }

        &-8 {
            margin-left: 8px;
        }

        &-12 {
            margin-left: 12px;
        }

        &-16 {
            margin-left: 16px;
        }

        &-20 {
            margin-left: 20px;
        }

        &-24 {
            margin-left: 24px;
        }
    }

    .mt {
        &-auto {
            margin-top: auto;
        }

        &-2 {
            margin-top: 2px;
        }

        &-4 {
            margin-top: 4px;
        }

        &-8 {
            margin-top: 8px;
        }

        &-12 {
            margin-top: 12px;
        }

        &-16 {
            margin-top: 16px;
        }

        &-20 {
            margin-top: 20px;
        }

        &-24 {
            margin-top: 24px;
        }
    }

    .mb {
        &-auto {
            margin-bottom: auto;
        }

        &-2 {
            margin-bottom: 2px;
        }

        &-4 {
            margin-bottom: 4px;
        }

        &-8 {
            margin-bottom: 8px;
        }

        &-12 {
            margin-bottom: 12px;
        }

        &-16 {
            margin-bottom: 16px;
        }

        &-20 {
            margin-bottom: 20px;
        }

        &-24 {
            margin-bottom: 24px;
        }
    }

    .text {
        &-light {
            font-weight: 300;
        }

        &-regular {
            font-weight: 400;
        }

        &-medium {
            font-weight: 500;
        }

        &-bold {
            font-weight: 600;
        }

        &-uppercase {
            text-transform: uppercase;
        }

        &-lowercase {
            text-transform: lowercase;
        }

        &-capitalize {
            text-transform: capitalize;
        }

        &-center {
            text-align: center;
        }

        &-right {
            text-align: right;
        }

        &-8 {
            font-size: ${rem(8)};
        }

        &-10 {
            font-size: ${rem(10)};
        }

        &-12 {
            font-size: ${rem(12)};
        }

        &-14 {
            font-size: ${rem(14)};
        }

        &-16 {
            font-size: ${rem(16)};
        }

        &-18 {
            font-size: ${rem(18)};
        }

        &-20 {
            font-size: ${rem(20)};
        }

        &-22 {
            font-size: ${rem(22)};
        }

        &-24 {
            font-size: ${rem(24)};
        }
    }

    .tiptap-content {
        font-size: ${rem(14)};
        word-break: break-word;

        &:focus-visible {
            outline: none;
        }

        .ProseMirror > * + *,
        > * + * {
            margin-top: 0.75em;
        }

        .ProseMirror > *:first-child,
        > *:first-child {
            margin-top: 0;
        }

        .ProseMirror > *:last-child,
        > *:last-child {
            margin-bottom: 0;
        }

        ul {
            list-style-type: circle;
        }

        ul,
        ol {
            margin: 0.8em 0;
            padding: 0 0 0 2em;

            li {
                > ul,
                > ol {
                    margin: 0;
                }

                p {
                    display: inline;
                    margin: 0;
                }
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        p {
            margin: 0.8em 0;
        }

        a {
            text-decoration: underline;
        }

        hr {
            border: none;
            border-top: 2px solid ${({ theme }) => theme.colors.grey[300]};
            margin: 1.5rem 0;
        }

        p.is-editor-empty:first-child {
            &::before {
                content: attr(data-placeholder);
                float: left;
                color: ${({ theme }) => theme.colors.grey[700]};
                pointer-events: none;
                height: 0;
            }
        }
    }
`;
