import { Fragment } from "react";
import RSelect from "react-select";

import Label from "../label/label.component";
import Error from "../error/error.component";

import { TbSearch, TbChevronDown } from "react-icons/tb";

import SelectWrapper, { SELECT_CLASS_NAME } from "./select.styles";

import { SelectProps } from "./select.interfaces";
import { IValueLabel } from "../../../common/interfaces/react-select.interfaces";

export const SelectSearchIndicator = () => (
  <div className={`${SELECT_CLASS_NAME}__indicator`}>
    <TbSearch />
  </div>
);

export const SelectCaretIndicator = () => (
  <div className={`${SELECT_CLASS_NAME}__indicator`}>
    <TbChevronDown />
  </div>
);

export const SelectEmptyComponent = () => null;

const Select = <
  Option extends IValueLabel<any, string>,
  isMulti extends boolean = false
>({
  required,
  disabled,
  $minWidth,
  $width,
  $maxWidth,
  $hoverActiveMinWidth,
  label,
  labelColor,
  labelTooltip,
  hasError,
  errorMessage,
  removeErrorMessage,
  $spacing,
  $size,
  className,
  variant,
  searchIndicator,
  customCursor,
  useMinimalOptionStyles,
  noDefaultBgBorder,
  disableIndicatorRotation,
  displayFlex,
  flex,
  flexDirection,
  flexWrap,
  alignItems,
  justifyContent,
  ...rest
}: SelectProps<Option, isMulti>) => {
  return (
    <Fragment>
      {label ? (
        <Label
          required={required}
          htmlFor={rest.id}
          $color={labelColor}
          tooltip={labelTooltip}
        >
          {label}
        </Label>
      ) : null}

      <SelectWrapper
        variant={variant}
        hasError={hasError}
        $spacing={$spacing}
        $minWidth={$minWidth}
        $width={$width}
        $maxWidth={$maxWidth}
        $hoverActiveMinWidth={$hoverActiveMinWidth}
        $size={$size}
        className={className}
        $disabled={disabled}
        customCursor={customCursor}
        useMinimalOptionStyles={useMinimalOptionStyles}
        noDefaultBgBorder={noDefaultBgBorder}
        displayFlex={displayFlex}
        flex={flex}
        flexDirection={flexDirection}
        flexWrap={flexWrap}
        alignItems={alignItems}
        justifyContent={justifyContent}
        disableIndicatorRotation={searchIndicator || disableIndicatorRotation}
      >
        <RSelect
          isDisabled={disabled}
          menuPosition="fixed"
          menuShouldBlockScroll
          menuPlacement="auto"
          classNamePrefix={SELECT_CLASS_NAME}
          maxMenuHeight={150}
          components={{
            IndicatorSeparator: SelectEmptyComponent,
            DropdownIndicator: searchIndicator
              ? SelectSearchIndicator
              : SelectCaretIndicator,
          }}
          {...rest}
        />

        {hasError && !removeErrorMessage && (
          <Error>{errorMessage || "Field is required"}</Error>
        )}
      </SelectWrapper>
    </Fragment>
  );
};

export default Select;
