import { BREAKPOINTS } from "../../common/data/constants";
import {
  ISpace,
  SpacingStyles,
  IColors,
  ColorStyles,
  IFlex,
  FlexStyles,
  IAlign,
  IDownResponsive,
  ResponsiveStyles,
} from "../../common/interfaces/theme-options.interfaces";

export function spacing({ $spacing }: ISpace) {
  if (!$spacing) {
    return null;
  }

  const styles: SpacingStyles = {};

  // paddings
  const { p, px, py, pl, pr, pb, pt } = $spacing;

  // margins
  const { m, mx, my, ml, mr, mb, mt } = $spacing;

  // paddings
  if (px) {
    styles.paddingLeft = px;
    styles.paddingRight = px;
  }

  if (py) {
    styles.paddingTop = py;
    styles.paddingBottom = py;
  }

  if (pl) {
    styles.paddingLeft = pl;
  }

  if (pr) {
    styles.paddingRight = pr;
  }

  if (pb) {
    styles.paddingBottom = pb;
  }

  if (pt) {
    styles.paddingTop = pt;
  }

  if (p) {
    styles.padding = p;
  }

  // margins
  if (mx) {
    styles.marginLeft = mx;
    styles.marginRight = mx;
  }

  if (my) {
    styles.marginTop = my;
    styles.marginBottom = my;
  }

  if (ml) {
    styles.marginLeft = ml;
  }

  if (mr) {
    styles.marginRight = mr;
  }

  if (mb) {
    styles.marginBottom = mb;
  }

  if (mt) {
    styles.marginTop = mt;
  }

  if (m) {
    styles.margin = m;
  }

  return {
    ...styles,
  };
}

export function colors({ color, bg, hoverColor, hoverBg }: IColors) {
  const styles: ColorStyles = {};
  if (color) {
    styles.color = color;
  }

  if (bg) {
    styles.backgroundColor = bg;
  }

  // hover
  if (hoverColor) {
    styles[":hover"] = {
      color: hoverColor,
    };
  }

  if (hoverBg) {
    styles[":hover"] = {
      ...styles[":hover"],
      backgroundColor: hoverBg,
    };
  }
  return {
    ...styles,
  };
}

export function flex({
  displayFlex,
  flex,
  flexDirection,
  justifyContent,
  alignItems,
  flexWrap,
}: IFlex) {
  const styles: FlexStyles = {};

  if (typeof displayFlex === "boolean" && displayFlex) {
    styles.display = "flex";
  }

  if (flex) {
    styles.flex = flex;
  }

  if (flexDirection) {
    styles.flexDirection = flexDirection;
  }

  if (justifyContent) {
    styles.justifyContent = justifyContent;
  }

  if (alignItems) {
    styles.alignItems = alignItems;
  }

  if (flexWrap) {
    styles.flexWrap = flexWrap;
  }

  return {
    ...styles,
  };
}

export function textAlignment({ textAlign }: IAlign) {
  if (textAlign) {
    return { textAlign };
  }
  return null;
}

export function down({ $down }: IDownResponsive) {
  if (!$down) {
    return null;
  }

  const { xxl, xl, lg, md, sm } = $down;

  const styles: ResponsiveStyles = {};

  if (xxl) {
    styles[`@media screen and (max-width: calc(${BREAKPOINTS["xxl"]} - 1px))`] =
      spacing({
        $spacing: xxl,
      })!;
  }

  if (xl) {
    styles[`@media screen and (max-width: calc(${BREAKPOINTS["xl"]} - 1px))`] =
      spacing({
        $spacing: xl,
      })!;
  }

  if (lg) {
    styles[`@media screen and (max-width: calc(${BREAKPOINTS["lg"]} - 1px))`] =
      spacing({
        $spacing: lg,
      })!;
  }

  if (md) {
    styles[`@media screen and (max-width: calc(${BREAKPOINTS["md"]} - 1px))`] =
      spacing({
        $spacing: md,
      })!;
  }

  if (sm) {
    styles[`@media screen and (max-width: calc(${BREAKPOINTS["sm"]} - 1px))`] =
      spacing({
        $spacing: sm,
      })!;
  }

  return {
    ...styles,
  };
}

const styledOptions = {
  spacing,
  textAlignment,
  flex,
  colors,
};

export default styledOptions;
