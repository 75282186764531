import styled from "styled-components";
import { flex, spacing } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

import { ISpinnerProps } from "./spinner.interfaces";

export const SpinnerEl = styled.div`
  display: inline-block;

  &::after {
    content: " ";
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: ${({ theme }) => theme?.colors?.primary.base || "#FF764A"}
      transparent ${({ theme }) => theme?.colors?.primary.base || "#FF764A"}
      transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerWrapper = styled.div<ISpinnerProps>`
  height: ${({ $height, theme }) =>
    $height === "full-layout"
      ? `calc(100vh - (${theme.layout.topbarHeight} + ${theme.layout.topbarSpacer} + ${theme.layout.contentBottomPadding}))`
      : $height};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${spacing};
  ${flex};

  > span {
    margin-top: 15px;
    font-size: ${rem(15)};
    font-weight: 500;
    color: ${({ theme }) => theme?.colors?.grey[600] || "#C6CAD6"};
  }
`;

export default SpinnerWrapper;
