import styled from "styled-components";
import { spacing, flex } from "../../styles/Theme/styled-options";
import { ISpace, IFlex } from "../../common/interfaces/theme-options.interfaces";

const Separator = styled.div<ISpace & IFlex>`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.grey[100]};
  ${spacing};
  ${flex};
`;

export default Separator;
