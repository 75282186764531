import styled from "styled-components";
import { spacing, textAlignment } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

import { IDescriptionProps } from "./description.interfaces";

const Description = styled.p<IDescriptionProps>`
  color: ${({ $color, theme, variant }) =>
    $color
      ? $color
      : variant === "controls"
      ? theme.colors.grey[600]
      : variant === "card"
      ? theme.colors.darkBlue[300]
      : theme.colors.text};
  font-weight: 400;
  font-size: ${({ $size, variant }) =>
    rem(
      $size === "s"
        ? 12
        : $size === "m"
        ? 16
        : $size === "l"
        ? 20
        : $size
        ? $size
        : variant === "controls"
        ? 15
        : 14
    )};
  ${spacing};
  ${textAlignment};
`;

export default Description;
