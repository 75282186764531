import { Outlet } from "shared/providers/router/router.package";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";

import Sidebar from "../../components/sidebar/sidebar.component";
import Topbar from "../../components/topbar/topbar.component";
import Main from "../../components/main/main.component";
import BackgroundBar from "../../components/background-bar/background-bar.component";

import SharedLayout from "shared/components/layout/layout.styles";

const Layout = () => {
  const expanded = useReactiveVar(sidebarExpandedVar);

  return (
    <SharedLayout className={expanded ? "expanded" : undefined}>
      <BackgroundBar />
      <Topbar />
      <Sidebar />

      <Main>
        <Outlet />
      </Main>
    </SharedLayout>
  );
};

export default Layout;
