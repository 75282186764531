import { useApolloClient } from "shared/providers/apollo/apollo.package";
import { useNavigate } from "shared/providers/router/router.package";

import { removeLocalStorageItem } from "shared/utils/local-storage";
import { resetSchoolAccessVar } from "../common/apollo/vars/school-access.var";
import { resetBackgroundBarVar } from "../common/apollo/vars/background-bar.var";
import { resetDockVar } from "../common/apollo/vars/dock-var.var";
import { resetGlobalModalsVar } from "../common/apollo/vars/global-modals.var";
import { resetSidebarExpandedVar } from "../common/apollo/vars/sidebar.var";
import { resetTitleVar } from "../common/apollo/vars/title.var";

import { WEB_AUTH_TOKEN } from "shared/common/data/constants";

/**
 * Logs out the user by resetting the cached store and removes auth token from localStorage
 * @returns async logout function
 */

export const useLogout = () => {
  const navigate = useNavigate();
  const client = useApolloClient();

  const logout = async () => {
    removeLocalStorageItem(WEB_AUTH_TOKEN);

    await client.clearStore();

    resetSchoolAccessVar();
    resetBackgroundBarVar();
    resetDockVar();
    resetGlobalModalsVar();
    resetSidebarExpandedVar();
    resetTitleVar();

    navigate("/login");
  };

  return logout;
};
