import { ApolloClient } from "shared/providers/apollo/apollo.package";
import cache from "./cache";
import link from "./link";

const apolloClient = new ApolloClient({
  link,
  cache,
});

export default apolloClient;
