import { useMemo } from "react";
import { useNavigate } from "shared/providers/router/router.package";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";
import {
  schoolAccessLoadingVar,
  schoolAccessVar,
} from "../../common/apollo/vars/school-access.var";
import { resetDockVar } from "../../common/apollo/vars/dock-var.var";
import { resetBackgroundBarVar } from "../../common/apollo/vars/background-bar.var";

import { useLogout } from "../../hooks/useLogout.hook";
import { useAuth } from "../../hooks/useAuth.hook";

import SharedSidebar from "shared/components/layout/sidebar/sidebar.component";
import {
  TbSettings,
  TbUsers,
  TbListDetails,
  TbReportAnalytics,
  TbTransferIn,
  TbEdit,
} from "shared/providers/react-icons/tb.package";

import { ISidebarRoute } from "shared/components/layout/sidebar/sidebar.interfaces";
import {
  ProviderEnum,
  RoleTypeEnum,
  useGetPublicIntegrationQuery,
} from "../../generated/graphql";

import { setLocalStorageItem } from "shared/utils/local-storage";

import { WEB_AUTH_SCHOOL } from "shared/common/data/constants";

import logo from "shared/assets/logo-only.svg";

const Sidebar = () => {
  const schoolAccess = useReactiveVar(schoolAccessVar);
  const expanded = useReactiveVar(sidebarExpandedVar);

  const navigate = useNavigate();

  const logout = useLogout();
  const { user } = useAuth();

  const { data: cleverData } = useGetPublicIntegrationQuery({
    skip: schoolAccess?.role.type !== RoleTypeEnum.SchoolAdmin,
    variables: {
      schoolId: schoolAccess!.school.id,
      type: ProviderEnum.Clever,
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const pages = useMemo(() => {
    const data: ISidebarRoute[] = [];

    if (schoolAccess?.role.type === RoleTypeEnum.Parent) {
      data.push({
        icon: TbUsers,
        name: "Students",
        url: "/users",
      });
    } else {
      data.push({
        icon: TbListDetails,
        name: "Classes",
        url: "/classes",
      });

      if (
        schoolAccess?.role.type === RoleTypeEnum.SchoolAdmin ||
        schoolAccess?.role.type === RoleTypeEnum.Teacher
      ) {
        data.push(
          ...[
            {
              icon: TbUsers,
              name: "User Management",
              url: "/users",
            },
            {
              icon: TbEdit,
              name: "Anecdotals",
              url: "/anecdotals",
            },
            {
              icon: TbReportAnalytics,
              name: "Reporting",
              url: "/reporting",
            },
          ]
        );
      }

      if (schoolAccess?.role.type === RoleTypeEnum.SchoolAdmin) {
        if (
          cleverData?.getPublicIntegration.hasIntegration &&
          cleverData.getPublicIntegration.status
        ) {
          data.push({
            icon: TbTransferIn,
            name: "Data Sync",
            url: "/data-sync",
          });
        }
      }
    }

    return data;
  }, [
    schoolAccess?.role,
    cleverData?.getPublicIntegration.hasIntegration,
    cleverData?.getPublicIntegration.status,
  ]);

  const secondaryPages: ISidebarRoute[] | null = useMemo(() => {
    if (schoolAccess?.role.type === RoleTypeEnum.SchoolAdmin) {
      return [
        {
          icon: TbSettings,
          name: "School Admin",
          url: "/school-admin",
        },
      ];
    }

    return null;
  }, [schoolAccess]);

  const switcherData = useMemo(
    () => ({
      id: schoolAccess?.school.id || "default-practice-switcher",
      name: schoolAccess?.school.name || "Practice Software",
      logo: schoolAccess?.school ? schoolAccess?.school.logo?.url : logo,
    }),
    [schoolAccess?.school]
  );

  const switcherItems = useMemo(
    () =>
      schoolAccess && user?.schoolAccess?.length
        ? user.schoolAccess
            .filter((singleAccess) => !singleAccess.school.isDeleted)
            .map((singleAccess) => ({
              id: singleAccess.school.id,
              name: singleAccess.school.name,
              logo: singleAccess.school.logo?.url,
              closeOnClick: true,
              onClick: () => {
                schoolAccessLoadingVar(true);

                setTimeout(() => {
                  navigate("/dashboard");

                  setLocalStorageItem(WEB_AUTH_SCHOOL, singleAccess.school.id);
                  schoolAccessVar(singleAccess);

                  resetBackgroundBarVar();
                  resetDockVar();

                  schoolAccessLoadingVar(false);
                }, 200);
              },
            }))
            .sort((a, b) => {
              if (a.id === schoolAccess.school.id) {
                return -1;
              }

              if (b.id === schoolAccess.school.id) {
                return 1;
              }

              return 0;
            })
        : null,
    [schoolAccess, user?.schoolAccess, navigate]
  );

  return (
    <SharedSidebar
      pages={pages}
      secondaryPages={secondaryPages}
      expanded={expanded}
      setExpanded={sidebarExpandedVar}
      onLogout={logout}
      switcher={switcherData}
      switcherItems={switcherItems}
    />
  );
};

export default Sidebar;
