import styled from "styled-components";
import { spacing } from "../../styles/Theme/styled-options";

import { IDotProps } from "./dot.interfaces";

const Dot = styled.div<IDotProps>`
  position: relative;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${({ $size }) =>
    $size === "xxl"
      ? "128px"
      : $size === "xl"
      ? "56px"
      : $size === "l"
      ? "40px"
      : $size === "m"
      ? "32px"
      : $size === "s"
      ? "24px"
      : $size === "xs"
      ? "16px"
      : $size === "xxs"
      ? "12px"
      : $size};
  width: ${({ $size }) =>
    $size === "xxl"
      ? "128px"
      : $size === "xl"
      ? "56px"
      : $size === "l"
      ? "40px"
      : $size === "m"
      ? "32px"
      : $size === "s"
      ? "24px"
      : $size === "xs"
      ? "16px"
      : $size === "xxs"
      ? "12px"
      : $size};
  border-radius: 50%;
  background-color: ${({ $bgColor, theme }) =>
    $bgColor === "primary"
      ? theme.colors.primary.base
      : $bgColor === "grey"
      ? theme.colors.grey.base
      : $bgColor === "success" || $bgColor === "green"
      ? theme.colors.green.base
      : $bgColor === "error" || $bgColor === "red"
      ? theme.colors.red.base
      : $bgColor === "blue"
      ? theme.colors.blue.base
      : $bgColor === "yellow"
      ? theme.colors.yellow.base
      : $bgColor};
  flex-shrink: 0;
  ${spacing};

  &::after {
    content: "";
    width: 5px;
    height: 5px;
    display: inline-block;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease;
  }

  &.active {
    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export default Dot;
