import { Fragment, lazy } from "react";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { globalModalsVar } from "../../common/apollo/vars/global-modals.var";

import withSuspense from "shared/hoc/with-suspense/with-suspense.hoc";
import { ModalTypesEnum } from "../../common/enums/modal-types.enum";

const AddMessaging = withSuspense(
  lazy(() => import("../../pages/messaging/add-messaging/add-messaging.component")),
  true
);

const AddReport = withSuspense(
  lazy(() => import("../../pages/reporting/add-report/add-report.component")),
  true
);

const AddAnecdotal = withSuspense(
  lazy(() => import("../../pages/anecdotals/add-anecdotal/add-anecdotal.component")),
  true
);

const SingleAnecdotal = withSuspense(
  lazy(
    () =>
      import("../../pages/anecdotals/single-anecdotal/single-anecdotal.component")
  ),
  true
);

const AddUser = withSuspense(
  lazy(() => import("../../pages/users/add-user/add-user.component")),
  true
);

const SingleUser = withSuspense(
  lazy(() => import("../../pages/users/single-user/single-user.component")),
  true
);

const AddEditClass = withSuspense(
  lazy(() => import("../../pages/classes/add-edit-class/add-edit-class.component")),
  true
);

const AddTeacherAssignment = withSuspense(
  lazy(
    () =>
      import(
        "../../pages/classes/single-class/teachers-assignments/add-assignment/add-assignment.component"
      )
  ),
  true
);

const EditTeacherAssignment = withSuspense(
  lazy(
    () =>
      import(
        "../../pages/classes/single-class/teachers-assignments/edit-assignment/edit-assignment.component"
      )
  ),
  true
);

const SingleTeacherAssignment = withSuspense(
  lazy(
    () =>
      import(
        "../../pages/classes/single-class/teachers-assignments/single-assignment/single-assignment.component"
      )
  ),
  true
);

const SingleStudentAssignment = withSuspense(
  lazy(
    () =>
      import(
        "../../pages/classes/single-class/students-assignments/single-assignment/single-assignment.component"
      )
  ),
  true
);

const GlobalModals = () => {
  const modals = useReactiveVar(globalModalsVar);

  return (
    <Fragment>
      {modals.map(({ type, id, noURLChange, additional }, idx) =>
        type === ModalTypesEnum.ADD_USER ? (
          <AddUser
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            noURLChange={noURLChange}
            type={type}
            attachParentToUserId={additional?.attachParentToUserId}
          />
        ) : type === ModalTypesEnum.SINGLE_USER && id ? (
          <SingleUser
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            id={id}
            noURLChange={noURLChange}
            type={type}
            openIntegrations={additional?.openIntegrations}
          />
        ) : type === ModalTypesEnum.ADD_CLASS ||
          (type === ModalTypesEnum.EDIT_CLASS && id) ? (
          <AddEditClass
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            id={id}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.ADD_MESSAGE ? (
          <AddMessaging
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.ADD_ASSIGNMENT ? (
          <AddTeacherAssignment
            key={`${idx}-${type}-modal`}
            modalIdx={idx}
            preselectedClassId={additional?.classId}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.EDIT_ASSIGNMENT && id ? (
          <EditTeacherAssignment
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            id={id}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.SINGLE_ASSIGNMENT && id ? (
          <SingleTeacherAssignment
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            id={id}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.SINGLE_STUDENT_ASSIGNMENT && id ? (
          <SingleStudentAssignment
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            id={id}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.ADD_REPORT ? (
          <AddReport
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.ADD_ANECDOTAL ? (
          <AddAnecdotal
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            noURLChange={noURLChange}
            type={type}
          />
        ) : type === ModalTypesEnum.SINGLE_ANECDOTAL && id ? (
          <SingleAnecdotal
            key={`${idx}-${type}-${id || "modal"}`}
            modalIdx={idx}
            id={id}
            noURLChange={noURLChange}
            type={type}
          />
        ) : null
      )}
    </Fragment>
  );
};

export default GlobalModals;
