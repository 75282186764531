import { useCallback, useRef, useState } from "react";

import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { dockVar } from "../../common/apollo/vars/dock-var.var";
import { globalModalsVar } from "../../common/apollo/vars/global-modals.var";

import useOnClickOutside from "shared/hooks/useOutside.hook";

import DropdownMenu from "shared/components/dropdown-menu/dropdown-menu.component";
import DockWrapper, { DockDropdown } from "./dock.styles";
import { DropdownMenuListItem } from "shared/components/dropdown-menu/dropdown-menu.styles";

import { TbChevronDown, TbX } from "shared/providers/react-icons/tb.package";

const Dock = () => {
  const dockActionsRef = useRef<HTMLDivElement>(null);

  const [dockActionsState, setDockActionsState] = useState<HTMLDivElement | null>(
    null
  );

  const minimizedItems = useReactiveVar(dockVar);

  const [menuOpened, setMenuOpened] = useState(false);

  const closeDockMenu = useCallback(
    () => (menuOpened ? setMenuOpened(false) : null),
    [menuOpened]
  );

  useOnClickOutside(dockActionsRef, closeDockMenu);

  const removeDockItem = useCallback((idx: number) => {
    const newItems = [...dockVar()];

    if (idx > -1) {
      newItems.splice(idx, 1);
    }

    dockVar(newItems);
  }, []);

  if (!minimizedItems.length) {
    return null;
  }

  return minimizedItems.length > 4 ? (
    <DockDropdown ref={dockActionsRef}>
      <div
        ref={setDockActionsState}
        onClick={() => setMenuOpened((prevState) => !prevState)}
        className={`dock-dropdown-indicator${menuOpened ? " open" : ""}`}
      >
        Minimized Items {minimizedItems.length}
        {<TbChevronDown />}
      </div>

      <DropdownMenu
        $spacing={{
          p: "8px",
        }}
        $width="230px"
        referenceRef={dockActionsState}
        open={menuOpened}
      >
        {minimizedItems.map(({ type, id, name, noURLChange, additional }, idx) => (
          <DropdownMenuListItem
            key={idx}
            onClick={() =>
              globalModalsVar([
                {
                  id,
                  type,
                  noURLChange,
                  additional,
                },
              ])
            }
          >
            {name}

            <TbX
              className="dock-dropdown-closer"
              onClick={(e) => {
                e.stopPropagation();
                removeDockItem(idx);
              }}
            />
          </DropdownMenuListItem>
        ))}
      </DropdownMenu>
    </DockDropdown>
  ) : (
    <DockWrapper>
      {minimizedItems.map(({ name, type, id, noURLChange, additional }, idx) => (
        <li
          key={`${idx}-${type}-${id || "dock"}`}
          onClick={() =>
            globalModalsVar([
              {
                id,
                type,
                noURLChange,
                additional,
              },
            ])
          }
        >
          {name}

          <TbX
            onClick={(e) => {
              e.stopPropagation();
              removeDockItem(idx);
            }}
          />
        </li>
      ))}
    </DockWrapper>
  );
};

export default Dock;
