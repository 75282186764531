import { PropsWithChildren } from "react";

import AvatarTextWrapper from "./avatar-text.styles";

import Title from "../../components/title/title.styles";
import Avatar from "../../components/avatar/avatar.component";

import { IAvatarTextProps } from "./avatar-text.interface";

const AvatarText = ({
  text,
  asTitle,
  titleProps,
  description,
  avatarProps,
  children,
  ...rest
}: PropsWithChildren<IAvatarTextProps>) => {
  return (
    <AvatarTextWrapper {...rest}>
      <Avatar
        $size="s"
        $spacing={{
          mr: "8px",
        }}
        {...avatarProps}
      >
        {children}
      </Avatar>

      <div className="content">
        {asTitle ? (
          <Title {...titleProps}>{text}</Title>
        ) : (
          <div className="main">{text}</div>
        )}

        {description ? <div className="description">{description}</div> : null}
      </div>
    </AvatarTextWrapper>
  );
};

export default AvatarText;
