import { PropsWithChildren } from "react";

import SpinnerWrapper, { SpinnerEl } from "./spinner.styles";

import { ISpinnerProps } from "./spinner.interfaces";

const Spinner = ({ children, ...props }: PropsWithChildren<ISpinnerProps>) => {
  return (
    <SpinnerWrapper {...props}>
      <SpinnerEl />

      {children ? <span>{children}</span> : null}
    </SpinnerWrapper>
  );
};

export default Spinner;
