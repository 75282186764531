import styled from "styled-components";

const BackgroundBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.darkBlue.base};
  z-index: -1;
  height: ${({ theme }) => theme.layout.topbarHeight};
`;

export default BackgroundBar;
