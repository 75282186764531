const transformName = (
  firstName?: string | null,
  lastName?: string | null,
  extra?: string | null
): string =>
  (firstName || lastName
    ? `${firstName ? firstName : ""}${
        lastName ? (firstName ? " " : "") + lastName : ""
      }`
    : "N/A") + (extra ? ` (${extra})` : "");

export default transformName;
