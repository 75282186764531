import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import Avatar from "../../avatar/avatar.component";
import Description from "../../description/description.styles";
import DropdownMenu from "../../dropdown-menu/dropdown-menu.component";
import Select from "../../form/select/select.component";
import AsyncSelect from "../../form/select/async/async.component";
import Title from "../../title/title.styles";

import useOnClickOutside from "../../../hooks/useOutside.hook";

import TopBarWrapper from "./topbar.styles";
import { DropdownMenuListItem } from "../../dropdown-menu/dropdown-menu.styles";

import {
  TbBell,
  TbChevronDown,
  TbMessage,
  TbMenu2,
  TbLogout,
  TbConfetti,
  TbApps,
} from "react-icons/tb";

import { ITopbarProps } from "./topbar.interfaces";

const TopBar = ({
  userFirstName,
  userLastName,
  userTitle,
  userPhoto,
  userPhotoSize = "m",
  title,
  inactive,
  setExpanded,
  logout,
  inboxHandler,
  showNotifications,
  notificationsData,
  actionItems,
  quickItems,
}: ITopbarProps) => {
  const notificationsRef = useRef<HTMLDivElement>(null);
  const quickActionsRef = useRef<HTMLDivElement>(null);
  const userActionsRef = useRef<HTMLDivElement>(null);

  const [notificationsState, setNotificationsState] =
    useState<HTMLDivElement | null>(null);
  const [quickActionsState, setQuickActionsState] = useState<HTMLDivElement | null>(
    null
  );
  const [userActionsState, setUserActionsState] = useState<HTMLDivElement | null>(
    null
  );

  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const [quickActionsOpened, setQuickActionsOpened] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);

  const closeNotifications = useCallback(
    () => (notificationsOpened ? setNotificationsOpened(false) : null),
    [notificationsOpened]
  );

  const closeQuickActions = useCallback(
    () => (quickActionsOpened ? setQuickActionsOpened(false) : null),
    [quickActionsOpened]
  );

  const closeUserMenu = useCallback(
    () => (menuOpened ? setMenuOpened(false) : null),
    [menuOpened]
  );

  useOnClickOutside(notificationsRef, closeNotifications);

  useOnClickOutside(quickActionsRef, closeQuickActions);

  useOnClickOutside(userActionsRef, closeUserMenu);

  const onLogoutClick = useCallback(() => {
    logout();
    closeUserMenu();
  }, [logout, closeUserMenu]);

  return (
    <TopBarWrapper>
      <Helmet>
        <title>{title.metaTitle || "PRACTICE Software"}</title>
        <meta
          name="description"
          content={
            title.metaDescription ||
            "All in one tool for schools, students and grades."
          }
        />
      </Helmet>

      <div className="title-wrapper">
        <span className="mobile-hamburger" onClick={() => setExpanded(true)}>
          <TbMenu2 />
        </span>

        {inactive || title.type === "default" ? (
          <Title level={4} $color="#ffffff">
            {inactive ? "Inactive Account" : (title.value as string)}
          </Title>
        ) : title.type === "select" ? (
          <Select
            key={title.key}
            variant="transparent"
            $spacing={{
              p: "0",
            }}
            {...title.value}
          />
        ) : title.type === "multi-select" ? (
          <Select
            key={title.key}
            variant="transparent"
            $spacing={{
              p: "0",
            }}
            {...title.value}
            isMulti
          />
        ) : title.type === "async-select" ? (
          <AsyncSelect
            key={title.key}
            variant="transparent"
            $spacing={{
              p: "0",
            }}
            {...title.value}
          />
        ) : (
          <AsyncSelect
            key={title.key}
            variant="transparent"
            $spacing={{
              p: "0",
            }}
            {...title.value}
            isMulti
          />
        )}
      </div>

      <div className="actions-wrapper">
        {!showNotifications ? null : (
          <div className="single-action-wrapper" ref={notificationsRef}>
            <div
              ref={setNotificationsState}
              className={`single-action${notificationsOpened ? " open" : ""}`}
              onClick={() => setNotificationsOpened((prevState) => !prevState)}
            >
              <TbBell /> <span className="hide-under-md">Notifications</span>
            </div>

            <DropdownMenu
              as="div"
              referenceRef={notificationsState}
              open={notificationsOpened}
              $width="250px"
              $spacing={{
                p: "8px",
              }}
            >
              {notificationsData || (
                <>
                  You're all set! No new notifications.{" "}
                  <TbConfetti className="color-green" />
                </>
              )}
            </DropdownMenu>
          </div>
        )}

        {!inboxHandler ? null : (
          <div className="single-action-wrapper">
            <div onClick={inboxHandler} className="single-action">
              <TbMessage /> <span className="hide-under-md">Inbox</span>
            </div>
          </div>
        )}

        {quickItems?.length ? (
          <div className="single-action-wrapper" ref={quickActionsRef}>
            <div
              ref={setQuickActionsState}
              className={`single-action${quickActionsOpened ? " open" : ""}`}
              onClick={() => setQuickActionsOpened((prevState) => !prevState)}
            >
              <TbApps />
            </div>

            <DropdownMenu
              referenceRef={quickActionsState}
              open={quickActionsOpened}
              $width="250px"
              $spacing={{
                p: "8px",
              }}
            >
              {quickItems.map(({ text, onClick, icon }, idx) => (
                <DropdownMenuListItem key={idx} onClick={onClick}>
                  {icon ? React.createElement(icon) : null} {text}
                </DropdownMenuListItem>
              ))}
            </DropdownMenu>
          </div>
        ) : null}

        <div ref={userActionsRef}>
          <div
            ref={setUserActionsState}
            onClick={() => setMenuOpened((prevState) => !prevState)}
            className={`user-actions${menuOpened ? " open" : ""}`}
          >
            <Avatar
              $size={userPhotoSize}
              $bg={userPhoto}
              $spacing={{
                mr: "8px",
              }}
            >
              {userPhoto
                ? null
                : `${userFirstName.charAt(0)}${userLastName.charAt(0)}`}
            </Avatar>

            <div className="user-text">
              <Title as="h6" level={6}>
                {`${userFirstName} ${userLastName}`}
              </Title>

              {userTitle ? <Description>{userTitle}</Description> : null}
            </div>

            <TbChevronDown className="caret" />
          </div>

          <DropdownMenu as="div" referenceRef={userActionsState} open={menuOpened}>
            {actionItems?.length ? (
              <ul className="user-menu">
                {actionItems.map(({ text, onClick, icon }, idx) => (
                  <DropdownMenuListItem key={idx} onClick={onClick}>
                    {icon ? React.createElement(icon) : null} {text}
                  </DropdownMenuListItem>
                ))}
              </ul>
            ) : null}

            <div className="user-logout-wrapper">
              <DropdownMenuListItem as="div" onClick={onLogoutClick}>
                <TbLogout /> Logout
              </DropdownMenuListItem>
            </div>
          </DropdownMenu>
        </div>
      </div>
    </TopBarWrapper>
  );
};

export default TopBar;
