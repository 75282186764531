import { useEffect, useState, FC, PropsWithChildren } from "react";
import { toast } from "shared/providers/toast/toast.package";
import { useAuth } from "../../hooks/useAuth.hook";
import {
  bootIntercom,
  intercomUserObject,
  updateIntercom,
} from "./intercom-wrapper.utils";

// TODO: Add Intercom Integration Logic

const IntercomWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();

  const [booted, setBooted] = useState(false);

  const appId = "some_app_id";

  useEffect(
    () => {
      if (!booted) {
        if (appId) {
          bootIntercom(appId);
          setBooted(true);
        }
      } else {
        toast.error(
          "We have detected that Intercom has been changed so page will now refresh to take the effect of the changes you made."
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    },
    // eslint-disable-next-line
    [appId]
  );

  useEffect(() => {
    if (booted && user) {
      updateIntercom(intercomUserObject(user));
    }
  }, [user, booted]);

  return <>{children}</>;
};

export default IntercomWrapper;
