import { setContext } from "shared/providers/apollo/link/context.package";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "shared/utils/local-storage";

import { createUploadLink } from "shared/providers/apollo/apollo-upload.package";
import { onError } from "shared/providers/apollo/link/error.package";
import { WEB_AUTH_TOKEN } from "shared/common/data/constants";

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_GQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = getLocalStorageItem(WEB_AUTH_TOKEN);

  return {
    headers: {
      ...headers,
      ...(token ? { authorization: `Bearer ${token}` } : {}),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {
        removeLocalStorageItem(WEB_AUTH_TOKEN);
        window.location.reload();
      }
    }
  }
});

export default errorLink.concat(authLink.concat(httpLink));
