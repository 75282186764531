import { PropsWithChildren } from "react";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { schoolAccessLoadingVar } from "../../common/apollo/vars/school-access.var";

import Spinner from "shared/components/spinner/spinner.component";
import SharedMain from "shared/components/layout/main/main.component";

const Main = ({ children }: PropsWithChildren) => {
  const schoolAccessLoading = useReactiveVar(schoolAccessLoadingVar);

  if (schoolAccessLoading) {
    return <Spinner $height="full-layout" />;
  }

  return <SharedMain>{children}</SharedMain>;
};

export default Main;
