import { useNavigate } from "react-router-dom";

import Title from "../title/title.styles";
import Description from "../description/description.styles";
import Button from "../button/button.component";
import NotFoundWrapper from "./not-found.styles";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import notFoundImage from "../../assets/404.png";

import { INotFoundProps } from "./not-found.interfaces";

const NotFound = ({
  url,
  title,
  text,
  buttonText,
  buttonOnClick,
  hideHomepage,
  insideLayout,
}: INotFoundProps) => {
  const navigate = useNavigate();

  return (
    <NotFoundWrapper insideLayout={insideLayout}>
      <div className="text-wrapper">
        <img
          src={notFoundImage}
          alt="Not found resource"
          className="not-found-image"
        />

        <Title
          $spacing={{
            mb: "12px",
          }}
        >
          {title || "404 - You found a place that doesn't exist!"}
        </Title>

        <Description>{text || "Hopefully it will be back soon."}</Description>
      </div>

      {!hideHomepage && (
        <Button
          $spacing={{
            mb: "12px",
          }}
          onClick={
            buttonOnClick ? buttonOnClick : () => navigate(url || "/dashboard")
          }
        >
          {buttonText || "Go to homepage"}
        </Button>
      )}

      <div className="footer-wrapper">
        <Logo />
      </div>
    </NotFoundWrapper>
  );
};

export default NotFound;
