import Tooltip from "../../tooltip/tooltip.component";

import LabelWrapper from "./label.styles";
import Description from "../../description/description.styles";

import { ILabelProps } from "./label.interfaces";

const Label = ({
  actions,
  required,
  children,
  description,
  tooltip,
  secondaryColor,
  $color,
  $spacing = {
    m: "0 0 8px",
  },
  $down,
  ...props
}: ILabelProps) => {
  return (
    <LabelWrapper
      $spacing={$spacing}
      secondaryColor={secondaryColor}
      $color={$color}
      $down={$down}
    >
      <div className="label-container">
        <label {...props}>
          {children}
          {required && <span className="required">*</span>}
        </label>

        {tooltip ? <Tooltip showInformer>{tooltip}</Tooltip> : null}

        {actions ? <div className="ml-auto">{actions}</div> : null}
      </div>

      {description ? <Description as="span">{description}</Description> : null}
    </LabelWrapper>
  );
};

export default Label;
