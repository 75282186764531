import styled from "styled-components";

const LayoutWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.layout.background};
  min-height: 100vh;
  padding-bottom: ${({ theme }) => theme.layout.contentBottomPadding};
  margin-left: ${({ theme }) => theme.layout.collapsedWidth};
  transition: margin-left 0.2s ease;

  &.expanded {
    margin-left: ${({ theme }) => theme.layout.expandedWidth};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    margin-left: 0;

    &.expanded {
      margin-left: 0;
    }
  }
`;

export default LayoutWrapper;
