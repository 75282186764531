import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { backgroundBarVar } from "../../common/apollo/vars/background-bar.var";

import SharedBackgroundBar from "shared/components/layout/background-bar/background-bar.styles";

const BackgroundBar = () => {
  const data = useReactiveVar(backgroundBarVar);

  return (
    <SharedBackgroundBar
      style={{
        height: data?.height || undefined,
        backgroundColor: data?.backgroundColor || undefined,
      }}
    />
  );
};

export default BackgroundBar;
