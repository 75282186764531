import transformName from "shared/utils/transform-name";

// TODO: Update user to use Me Fragment

export const intercomUserObject = (user: any) => ({
  user_id: user.id,
  name: transformName(user.firstName, user.lastName),
  email: user.email,
  phone: user.phone,
});

export const updateIntercom = (data: any) => {
  if (window.Intercom) {
    window.Intercom("update", data);
  }
};

export const bootIntercom = (appId: string) => {
  if (window.Intercom) {
    window.Intercom("shutdown");

    window.Intercom("boot", {
      app_id: appId,
    });
  }
};
