import { Navigate, useLocation } from "react-router-dom";

import Spinner from "shared/components/spinner/spinner.component";
import UserInactive from "shared/components/user-inactive/user-inactive.component";

import { IProtectedRoute } from "./protected-route.interfaces";

const ProtectedRoute = ({
  loggedIn,
  loading,
  inactive,
  onLogout,
  children,
}: IProtectedRoute) => {
  const location = useLocation();

  if (loading) {
    return <Spinner $height="100vh" />;
  }

  if (loggedIn) {
    if (inactive) {
      return <UserInactive onLogout={onLogout} />;
    } else {
      return <>{children}</>;
    }
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default ProtectedRoute;
