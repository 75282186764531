import styled from "styled-components";
import { INotFoundProps } from "./not-found.interfaces";

const NotFoundWrapper = styled.div<Pick<INotFoundProps, "insideLayout">>`
  height: ${({ insideLayout, theme }) =>
    insideLayout
      ? `calc(100vh - (${theme.layout.topbarHeight} + ${theme.layout.topbarSpacer} + ${theme.layout.contentBottomPadding}))`
      : "100vh"};
  padding: ${({ insideLayout }) => (insideLayout ? "" : "8% 9% 40px")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .logo-wrapper {
    margin-bottom: 40px;
    > svg {
      width: 200px;
      max-width: 100%;
    }
  }

  .text-wrapper {
    margin-bottom: 40px;
  }

  .not-found-image {
    width: 300px;
    max-width: 100%;
    margin-bottom: 15px;
  }

  .footer-wrapper {
    margin-top: auto;

    > svg {
      width: 165px;
      max-width: 100%;
    }
  }
`;

export default NotFoundWrapper;
