import React, { useMemo } from "react";
import Avatar from "../../avatar/avatar.component";
import Checkbox from "../../form/checkbox/checkbox.component";

import StandardOptionWrapper from "./standard-option.styles";

import { TbBuilding, TbUserCircle } from "react-icons/tb";
import { IBaseStandardOptionProps } from "./standard-option.interfaces";
import { forwardRef } from "react";

const StandardOption = forwardRef<HTMLElement, IBaseStandardOptionProps>(
  (
    {
      id,
      text,
      description,
      avatarContent,
      avatarProps,
      avatarDefaultIcon,
      active,
      disabled,
      left,
      right,
      rightText,
      $spacing,
      as,
      removeDefaultActiveMarker,
      onClick,
      className,
    },
    ref
  ) => {
    const classList = useMemo(() => {
      const classes: string[] = [];

      if (className) {
        classes.push(className);
      }

      if (active) {
        classes.push("active");
      }

      if (disabled) {
        classes.push("disabled");
      }

      if (removeDefaultActiveMarker) {
        classes.push("no-marker");
      }

      return classes.join(" ");
    }, [className, active, disabled, removeDefaultActiveMarker]);

    return (
      <StandardOptionWrapper
        as={as}
        $spacing={$spacing}
        className={classList}
        onClick={onClick}
        ref={ref}
      >
        {left ? (
          <div
            className={`left-wrapper${
              left === "avatar"
                ? " left-wrapper--avatar"
                : left === "checkbox"
                ? " left-wrapper--checkbox"
                : " left-wrapper--icon"
            }`}
          >
            {left === "avatar" ? (
              <Avatar $size="s" {...avatarProps}>
                {avatarContent ? (
                  avatarContent
                ) : avatarDefaultIcon ? (
                  avatarDefaultIcon === "user" ? (
                    <TbUserCircle />
                  ) : (
                    <TbBuilding />
                  )
                ) : null}
              </Avatar>
            ) : left === "checkbox" ? (
              <Checkbox
                id={id}
                checked={!!active}
                disabled={!!disabled}
                inputProps={{
                  readOnly: true,
                }}
                $spacing={{
                  p: "0",
                }}
              />
            ) : (
              React.createElement(left)
            )}
          </div>
        ) : null}

        <div className="content">
          <div className="main">{text}</div>
          {description ? <div className="description">{description}</div> : null}
        </div>

        <div
          className={`right-wrapper${
            right
              ? right === "checkbox"
                ? " right-wrapper--checkbox"
                : " right-wrapper--icon"
              : rightText
              ? " right-wrapper--text"
              : " right-wrapper--default"
          }`}
        >
          {right ? (
            right === "checkbox" ? (
              <Checkbox
                id={id}
                checked={!!active}
                disabled={!!disabled}
                inputProps={{
                  readOnly: true,
                }}
                $spacing={{
                  p: "0",
                }}
              />
            ) : (
              React.createElement(right)
            )
          ) : (
            rightText
          )}
        </div>
      </StandardOptionWrapper>
    );
  }
);

export default StandardOption;
