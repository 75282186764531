import styled from "styled-components";
import { spacing } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

const StyledWrapper = styled.span`
  background-color: ${({ theme }) => theme.colors.blue.base};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  ${spacing};

  svg {
    font-size: ${rem(9)};
    color: #ffffff;
  }
`;

export default StyledWrapper;
