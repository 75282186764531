import styled from "styled-components";
import { spacing } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

import { IAvatarProps, IAvatarPreviewProps } from "./avatar.interfaces";

export const AvatarPreview = styled.div<IAvatarPreviewProps>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${({ $size }) =>
    $size === "xxl"
      ? "128px"
      : $size === "xl"
      ? "56px"
      : $size === "l"
      ? "40px"
      : $size === "m"
      ? "32px"
      : $size === "s"
      ? "24px"
      : $size === "xs"
      ? "16px"
      : "40px"};
  width: ${({ $size }) =>
    $size === "xxl"
      ? "128px"
      : $size === "xl"
      ? "56px"
      : $size === "l"
      ? "40px"
      : $size === "m"
      ? "32px"
      : $size === "s"
      ? "24px"
      : $size === "xs"
      ? "16px"
      : "40px"};
  border-radius: 50%;
  color: ${({ $color, theme }) =>
    $color === "grey"
      ? theme.colors.grey.base
      : $color === "success" || $color === "green"
      ? theme.colors.green.base
      : $color === "error" || $color === "red"
      ? theme.colors.red.base
      : $color === "blue"
      ? theme.colors.blue.base
      : $color === "yellow"
      ? theme.colors.yellow.base
      : $color === "orange"
      ? theme.colors.orange.base
      : $color || theme.colors.primary.base};
  font-size: ${({ $size }) =>
    rem(
      $size === "xxl"
        ? 28
        : $size === "xl"
        ? 20
        : $size === "l"
        ? 18
        : $size === "m"
        ? 14
        : $size === "s"
        ? 10
        : $size === "xs"
        ? 10
        : 16
    )};
  font-weight: 600;
  line-height: 1;
  background-color: ${({ $bgColor, theme }) =>
    $bgColor === "grey"
      ? theme.colors.grey[50]
      : $bgColor === "success" || $bgColor === "green"
      ? theme.colors.green[50]
      : $bgColor === "error" || $bgColor === "red"
      ? theme.colors.red[50]
      : $bgColor === "blue"
      ? theme.colors.blue[50]
      : $bgColor === "yellow"
      ? theme.colors.yellow[50]
      : $bgColor === "orange"
      ? theme.colors.orange[50]
      : $bgColor || theme.colors.primary[50]};
  border: 1px solid
    ${({ $borderColor, theme }) =>
      $borderColor === "grey"
        ? theme.colors.grey[50]
        : $borderColor === "success" || $borderColor === "green"
        ? theme.colors.green[50]
        : $borderColor === "error" || $borderColor === "red"
        ? theme.colors.red[50]
        : $borderColor === "blue"
        ? theme.colors.blue[50]
        : $borderColor === "yellow"
        ? theme.colors.yellow[50]
        : $borderColor === "orange"
        ? theme.colors.orange[50]
        : $borderColor || theme.colors.primary[50]};
  background-image: ${({ $bg }) => ($bg ? `url(${$bg})` : undefined)};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: ${({ $hasBoxShadow, theme }) =>
    $hasBoxShadow ? theme.boxShadow.base : undefined};
  cursor: ${({ $cursor }) => $cursor || undefined};

  > img {
    max-width: 70%;
    max-height: 70%;
  }
`;

const AvatarWrapper = styled.div<IAvatarProps>`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  ${spacing};

  input {
    display: none;
  }

  .deleter {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: ${rem(12)};
    color: ${({ theme }) => theme.colors.red.base};
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.grey[300]};
  }
`;

export default AvatarWrapper;
