import styled, { css } from "styled-components";
import { flex, spacing } from "../../styles/Theme/styled-options";
import rem from "../../utils/rem";

import { IButtonProps } from "./button.interfaces";

const ButtonWrapper = styled.button<IButtonProps>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: ${({ $height, iconOnly, $size, theme }) =>
    $height ? $height : iconOnly ? theme.heights[$size || "large"] : undefined};
  min-height: ${({ $size, theme }) => theme.heights[$size || "large"]};
  width: ${({ $width, isBlock, $size, iconOnly, theme }) =>
    $width
      ? $width
      : isBlock
      ? "100%"
      : iconOnly
      ? theme.heights[$size || "large"]
      : undefined};
  border: 1px solid
    ${({ $borderColor, variant, theme }) =>
      $borderColor
        ? $borderColor
        : variant === "secondary"
        ? theme.colors.grey[100]
        : variant === "tertiary"
        ? "#ffffff"
        : variant === "error"
        ? theme.colors.red.base
        : variant === "success"
        ? theme.colors.green.base
        : theme.colors.primary.base};
  border-radius: ${({ $borderRadius, theme }) =>
    $borderRadius || theme.spaceRadius.s};
  color: ${({ $color, variant, theme }) =>
    $color
      ? $color
      : variant === "secondary" || variant === "tertiary"
      ? theme.colors.darkBlue[500]
      : "#ffffff"};
  padding: ${({ $size, iconOnly }) =>
    $size === "medium"
      ? "9px"
      : $size === "small"
      ? "7px"
      : iconOnly
      ? "11px"
      : "11px 15px"};
  font-family: ${({ theme }) => theme.typography.font};
  font-size: ${rem(13)};
  font-weight: 500;
  background-color: ${({ $bgColor, variant, theme }) =>
    $bgColor
      ? $bgColor
      : variant === "secondary" || variant === "tertiary"
      ? "#ffffff"
      : variant === "error"
      ? theme.colors.red.base
      : variant === "success"
      ? theme.colors.green.base
      : theme.colors.primary.base};
  box-shadow: none;
  outline: none;
  appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
  ${spacing};
  ${flex};

  > span {
    + span {
      margin-left: 7px;
    }
  }

  img,
  svg {
    display: block;
  }

  &:hover:not(:disabled) {
    color: ${({ $hoverColor, variant, theme }) =>
      $hoverColor
        ? $hoverColor
        : variant === "secondary" || variant === "tertiary"
        ? theme.colors.primary.base
        : undefined};
    background-color: ${({ $hoverBgColor, variant, theme }) =>
      $hoverBgColor
        ? $hoverBgColor
        : variant === "primary"
        ? theme.colors.primary[400]
        : variant === "error"
        ? theme.colors.red[400]
        : variant === "success"
        ? theme.colors.green[400]
        : undefined};
    border-color: ${({ $hoverBorderColor, variant, theme }) =>
      $hoverBorderColor
        ? $hoverBorderColor
        : variant === "primary"
        ? theme.colors.primary[400]
        : variant === "error"
        ? theme.colors.red[400]
        : variant === "success"
        ? theme.colors.green[400]
        : undefined};
    outline: none;
  }

  &:focus:not(:disabled) {
    color: ${({ $hoverColor, variant, theme }) =>
      $hoverColor
        ? $hoverColor
        : variant === "secondary" || variant === "tertiary"
        ? theme.colors.darkBlue[500]
        : undefined};
    background-color: ${({ $hoverBgColor, variant, theme }) =>
      $hoverBgColor
        ? $hoverBgColor
        : variant === "primary"
        ? theme.colors.primary.base
        : variant === "error"
        ? theme.colors.red.base
        : variant === "success"
        ? theme.colors.green.base
        : undefined};
    border-color: ${({ $hoverBorderColor, variant, theme }) =>
      $hoverBorderColor
        ? $hoverBorderColor
        : variant === "primary"
        ? theme.colors.primary.base
        : variant === "secondary"
        ? "#ffffff"
        : variant === "error"
        ? theme.colors.red.base
        : variant === "success"
        ? theme.colors.green.base
        : undefined};
    outline: 4px solid ${({ theme }) => theme.colors.primary[100]};
  }

  &:active:not(:disabled) {
    color: ${({ $hoverColor, variant, theme }) =>
      $hoverColor
        ? $hoverColor
        : variant === "secondary"
        ? theme.colors.darkBlue[500]
        : variant === "tertiary"
        ? theme.colors.primary[600]
        : undefined};
    background-color: ${({ $hoverBgColor, variant, theme }) =>
      $hoverBgColor
        ? $hoverBgColor
        : variant === "primary"
        ? theme.colors.primary[600]
        : variant === "secondary"
        ? theme.colors.grey[50]
        : variant === "error"
        ? theme.colors.red[600]
        : variant === "success"
        ? theme.colors.green[600]
        : undefined};
    border-color: ${({ $hoverBorderColor, variant, theme }) =>
      $hoverBorderColor
        ? $hoverBorderColor
        : variant === "primary"
        ? theme.colors.primary[600]
        : variant === "secondary"
        ? theme.colors.grey[100]
        : variant === "error"
        ? theme.colors.red[600]
        : variant === "success"
        ? theme.colors.green[600]
        : undefined};
    outline: none;
  }

  ${({ hideTextMobile, $size, theme }) =>
    hideTextMobile
      ? css`
          ${theme.breakpoints.down("sm")} {
            min-height: auto;
            padding: ${$size === "large" ? "12px" : undefined};

            .button-text-wrapper {
              display: none;
            }
          }
        `
      : undefined}

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ variant, theme }) =>
      variant === "secondary" || variant === "tertiary"
        ? undefined
        : theme.colors.grey[200]};
    color: ${({ theme }) => theme.colors.grey[600]};
    border-color: ${({ variant, theme }) =>
      variant === "secondary"
        ? theme.colors.grey[600]
        : variant === "tertiary"
        ? undefined
        : theme.colors.grey[200]};
  }
`;

export default ButtonWrapper;
