import { PropsWithChildren } from "react";
import ErrorWrapper from "./error.styles";

import { TbAlertTriangle } from "react-icons/tb";

import { IErrorProps } from "./error.interfaces";

const Error = ({ children, ...props }: PropsWithChildren<IErrorProps>) => {
  return (
    <ErrorWrapper {...props}>
      <TbAlertTriangle /> {children}
    </ErrorWrapper>
  );
};

export default Error;
