import { PropsWithChildren } from "react";
import { Navigate, useLocation } from "shared/providers/router/router.package";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { schoolAccessVar } from "../../common/apollo/vars/school-access.var";

const SchoolProtectedRoute = ({ children }: PropsWithChildren) => {
  const schoolAccess = useReactiveVar(schoolAccessVar);
  const location = useLocation();

  if (!schoolAccess) {
    return <Navigate to="/school-selector" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default SchoolProtectedRoute;
