import { getLocalStorageItem } from "shared/utils/local-storage";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { districtVar } from "../common/apollo/vars/district.var";

import { MeFieldsFragment, useMeQuery } from "../generated/graphql";
import { WEB_AUTH_TOKEN } from "shared/common/data/constants";

export interface IAuthUser {
  user?: MeFieldsFragment;
  loading?: boolean;
}

/**
 * Get current logged user
 * @returns logged user object
 */

export const useAuth = (): IAuthUser => {
  const district = useReactiveVar(districtVar);
  const token = getLocalStorageItem(WEB_AUTH_TOKEN);

  const { data, loading } = useMeQuery({
    skip: !token || !district?.id,
    variables: {
      districtId: district?.id as string,
    },
  });

  return {
    user: data?.me,
    loading,
  };
};
