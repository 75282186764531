import React, { Fragment, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from "uuid";

import StyledInformer from "./tooltip.styles";
import { TbQuestionMark } from "react-icons/tb";

import { ITooltipProps } from "./tooltip.interfaces";

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  showInformer,
  informerIcon,
  $spacing,
  id,
  ...props
}) => {
  const tooltipId = useMemo(() => id || uuidv4(), [id]);

  return (
    <Fragment>
      {showInformer ? (
        <StyledInformer
          data-tip
          data-for={tooltipId}
          $spacing={{
            ml: "5px",
            ...$spacing,
          }}
        >
          {informerIcon ? informerIcon : <TbQuestionMark />}
        </StyledInformer>
      ) : null}

      <ReactTooltip
        effect="solid"
        backgroundColor="#ffffff"
        textColor="#000000"
        delayHide={200}
        clickable
        id={tooltipId}
        {...props}
        className={`tooltip-cmp${props.className ? ` ${props.className}` : ""}`}
      >
        {children}
      </ReactTooltip>
    </Fragment>
  );
};

export default Tooltip;
