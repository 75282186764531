import { Fragment } from "react";

import { AsyncPaginate } from "react-select-async-paginate";

import Label from "../../label/label.component";
import Error from "../../error/error.component";

import { SelectCaretIndicator, SelectSearchIndicator } from "../select.component";

import SelectWrapper, { SELECT_CLASS_NAME } from "../select.styles";

import { AsyncSelectProps } from "./async.interfaces";
import { IValueLabel } from "../../../../common/interfaces/react-select.interfaces";

const AsyncSelect = <
  Option extends IValueLabel<any, string>,
  isMulti extends boolean = false
>({
  required,
  disabled,
  $minWidth,
  $width,
  $maxWidth,
  $hoverActiveMinWidth,
  label,
  labelColor,
  labelTooltip,
  hasError,
  errorMessage,
  removeErrorMessage,
  $spacing,
  $size,
  className,
  variant,
  searchIndicator,
  customCursor,
  useMinimalOptionStyles,
  noDefaultBgBorder,
  disableIndicatorRotation,
  displayFlex,
  flex,
  flexDirection,
  flexWrap,
  alignItems,
  justifyContent,
  getData,
  ...rest
}: AsyncSelectProps<Option, isMulti>) => {
  return (
    <Fragment>
      {label ? (
        <Label
          required={required}
          htmlFor={rest.id}
          $color={labelColor}
          tooltip={labelTooltip}
        >
          {label}
        </Label>
      ) : null}

      <SelectWrapper
        variant={variant}
        hasError={hasError}
        $spacing={$spacing}
        $minWidth={$minWidth}
        $width={$width}
        $maxWidth={$maxWidth}
        $hoverActiveMinWidth={$hoverActiveMinWidth}
        $size={$size}
        className={className}
        $disabled={disabled}
        customCursor={customCursor}
        useMinimalOptionStyles={useMinimalOptionStyles}
        noDefaultBgBorder={noDefaultBgBorder}
        disableIndicatorRotation={searchIndicator || disableIndicatorRotation}
        displayFlex={displayFlex}
        flex={flex}
        flexDirection={flexDirection}
        flexWrap={flexWrap}
        alignItems={alignItems}
        justifyContent={justifyContent}
      >
        <AsyncPaginate
          isDisabled={disabled}
          menuPosition="fixed"
          menuShouldBlockScroll
          menuPlacement="auto"
          classNamePrefix={SELECT_CLASS_NAME}
          maxMenuHeight={150}
          components={{
            DropdownIndicator: searchIndicator
              ? SelectSearchIndicator
              : SelectCaretIndicator,
          }}
          {...rest}
          defaultOptions
          loadOptions={getData}
        />

        {hasError && !removeErrorMessage && (
          <Error>{errorMessage || "Field is required"}</Error>
        )}
      </SelectWrapper>
    </Fragment>
  );
};

export default AsyncSelect;
